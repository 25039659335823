<cb-dialog dialogHeading="{{mode}} Working Drawing">
    <form #workingDrawingForm="ngForm">
        <mat-tab-group class="tab-container" mat-stretch-tabs="false">
            <mat-tab label="Working Drawing Details">
                <cb-working-drawing-scheme-details class="cb-margin-2x"
                                                   *cbWaitFor="data && mappedItem && lotMappedItem"
                                                   [mappedItem]="mappedItem"
                                                   [lotMappedItem]="lotMappedItem">
                </cb-working-drawing-scheme-details>
            </mat-tab>
            <mat-tab label="Working Drawing Items">
                <cb-working-drawing-items [mappedItem]="mappedItem"
                                          [parentForm]="workingDrawingForm">
                </cb-working-drawing-items>
            </mat-tab>
            <mat-tab label="Assigned Changes">
                <cb-working-drawings-assigned-changes *cbWaitFor="mappedItem"
                                                      [mappedItem]="mappedItem"
                                                      [parentForm]="workingDrawingForm">
                </cb-working-drawings-assigned-changes>
            </mat-tab>
        </mat-tab-group>
    </form>
    <footer class="end-center flex-row">
        <button color="primary"
                mat-raised-button
                [disabled]="workingDrawingForm.invalid || workingDrawingForm.pristine"
                (cbClick)="saveAndSetPristine(workingDrawingForm)">Save</button>
        <button mat-raised-button
                (click)="cancel(!workingDrawingForm.pristine)">Cancel</button>
    </footer>
</cb-dialog>
