<ng-container *ngIf="productCentreIsEnabled; else disabledMessage">
    <div class="start-center flex-row cb-margin">
        <h3 class="mat-title flex">
            Product Centre Configuration
        </h3>
        <button mat-raised-button
                color="primary"
                (click)="addNewFilter()">
            Add Filter
        </button>
    </div>
    <cb-table class="cb-margin-top cb-margin-left-3x cb-margin-right-3x"
              label="Filters"
              [stringColumns]="filterColumns"
              [minWidth]="1000"
              [loaded]="searchResultsLoaded"
              [maxHeightOffset]="271">
        <tbody cbTableBody>
        <ng-container *ngFor="let filterConfiguration of searchResults">
            <tr>
                <td>
                    {{ filterConfiguration.name }}
                </td>
                <td>
                    {{ filterConfiguration.attributes.length }}
                </td>
                <cb-td-checkbox [value]="filterConfiguration.isActive"></cb-td-checkbox>
                <td cbTableStickyCol="end"
                    cbTableShrinkCol>
                    <button mat-icon-button
                            (click)="editFilter(filterConfiguration)">
                        <mat-icon>
                            edit
                        </mat-icon>
                    </button>
                    <button class="cb-margin-right-2x"
                            mat-icon-button
                            (click)="deleteItem(filterConfiguration)">
                        <mat-icon>
                            delete
                        </mat-icon>
                    </button>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </cb-table>
</ng-container>
<ng-template #disabledMessage>
    <div class="start-center flex-row cb-disabled-message">
        <mat-icon class="cb-warn">
            warning
        </mat-icon>&nbsp;
        The Product Centre functionality has not been turned on.
    </div>
</ng-template>
