import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormsModule } from '@angular/forms';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { LotSummaryComponent } from './lot-summary/lot-summary.component';
import { CbStepperModule } from '@app/shared/components/stepper/stepper.module';
import { LotSummaryStepperComponent } from './lot-summary-stepper/lot-summary-stepper.component';
import { CbHandleInvalidImageModule } from '@app/shared/directives/handle-invalid-image/handle-invalid-image.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { CbDasherModule } from 'projects/cb-hub-lib/src/lib/pipe/dasher/dasher.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { LotSummaryInfoComponent } from './lot-summary-info/lot-summary-info.component';
import { LotSummaryImagesComponent } from './lot-summary-images/lot-summary-images.component';
import { LotSummaryDetailsComponent } from './lot-summary-details/lot-summary-details.component';
import { LotSummaryBuildDetailsComponent } from './lot-summary-build-details/lot-summary-build-details.component';
import { LotSummaryClassicCareComponent } from './lot-summary-classic-care/lot-summary-classic-care.component';
import { LotSummaryPurchaserComponent } from './lot-summary-purchaser/lot-summary-purchaser.component';
import { LotSummaryInsuranceDetailsComponent } from './lot-summary-insurance-details/lot-summary-insurance-details.component';
import { LotSummaryClassicContactsComponent } from './lot-summary-classic-contacts/lot-summary-classic-contacts.component';
import { LotSummaryWebsitePublishingComponent } from './lot-summary-website-publishing/lot-summary-website-publishing.component';
import { CbAlertCardModule } from '@app/shared/components/alert-card/alert-card.module';
import { LotSummaryAlertsComponent } from './lot-summary-alerts/lot-summary-alerts.component';
import { LotSummaryMilestonesComponent } from './lot-summary-milestones/lot-summary-milestones.component';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { LotDescriptionComponent } from './lot-description/lot-description.component';
import {MatButtonModule} from '@angular/material/button';

const MAT_MODULES = [
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    MatExpansionModule
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxSliderModule,
        FormsModule,
        CbFormsModule,
        CbDisplayValueModule,
        MatButtonModule,
        CbStepperModule,
        CbHandleInvalidImageModule,
        CbDasherModule,
        CbWaitForModule,
        CbEnumToLabelModule,
        CbAlertCardModule,
        CbDatePipeModule,
        ...MAT_MODULES
    ],
    exports: [
        LotSummaryComponent
    ],
    declarations: [
        LotSummaryComponent,
        LotSummaryStepperComponent,
        LotSummaryImagesComponent,
        LotSummaryWebsitePublishingComponent,
        LotSummaryInfoComponent,
        LotSummaryDetailsComponent,
        LotSummaryClassicContactsComponent,
        LotSummaryBuildDetailsComponent,
        LotSummaryPurchaserComponent,
        LotSummaryInsuranceDetailsComponent,
        LotSummaryClassicCareComponent,
        LotSummaryAlertsComponent,
        LotSummaryMilestonesComponent,
        LotDescriptionComponent
    ],
})

export class LotSummaryModule { }
