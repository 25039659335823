<ng-container *cbWaitFor="(documents$$ | async) && (documentGroups$$ | async)">
    <mat-card class="cb-margin flex-col flex"
              [ngClass]="{ 'mat-card': !noCard }">
        <div *ngIf="!(hideSearch && hideUploadButton && hideDeletedToggle)"
             class="cb-margin-bottom start-center flex-row">
            <div *ngIf="!hideSearch"
                 class="cb-margin-right-3x start-center flex-row flex">
                <mat-icon class="cb-margin-right-2x">search</mat-icon>
                <mat-form-field class="flex">
                    <mat-label>Search Documents</mat-label>
                    <input matInput
                           type="text"
                           aria-label="Search Documents"
                           [formControl]="searchText"
                           name="{{entity}}-documents-search"/>
                </mat-form-field>
            </div>
            <mat-checkbox *ngIf="!hideDeletedToggle"
                          name="showDeleted"
                          class="cb-margin-right-3x"
                          [(ngModel)]="showDeleted"
                          (change)="showDeletedChanged()">Show Deleted</mat-checkbox>
            <button (click)="addDocumentStubs()"
                    *ngIf="!hideDocumentStubButton"
                    [disabled]="false"
                    class="header-button cb-margin-right-2x"
                    mat-raised-button
                    color="primary">
                Add Document Stub
            </button>
            <button (click)="uploadDocument()"
                    *ngIf="!hideUploadButton"
                    [disabled]="!edit"
                    class="header-button"
                    mat-raised-button
                    color="primary">
                Upload Document
            </button>
        </div>
        <cb-document-list *ngIf="(documents$$ | async) && (documentGroups$$ | async)"
                          [documents]="filteredDocuments$$ | async"
                          [documentGroups]="documentGroups$$ | async"
                          (documentsChange)="onDocumentsChange()"
                          [showDeleted]="showDeleted"
                          [showDeletedEvent]="showDeletedEvent"
                          [pushAndRefreshDocumentsListEvent]="pushAndRefreshDocumentsListEvent"
                          [permissions]="permissions"
                          [lockRequired]="lockRequired"
                          [$baseUri]="entity.$logicService.$baseUri"
                          [$documentEntityId]="entity.documentEntityId"
                          [hideRenewalColumn]="hideRenewalColumn"
                          [edit]="edit"
                          [prefixColumns]="prefixColumns"
                          [enableCheckbox]="enableCheckbox"
                          [allowReviewAtUpload]="allowReviewAtUpload"
                          [(selectedDocumentGroup)]="selectedDocumentGroup">
        </cb-document-list>
    </mat-card>
</ng-container>
