import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AllManualOrdersComponent } from './all-manual-orders.component';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbLoadingSpinnerModule, CbDasherModule, CbTableModule, CbSelectListModule } from 'cb-hub-lib';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { NgModule } from '@angular/core';
import { CbHubTableModule } from '@app/shared/components/table/table.module';

import { CbButtonModule } from '@app/shared/components/button/button.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';

@NgModule({
    declarations: [
        AllManualOrdersComponent
    ],
    imports: [
        CbFormsModule,
        CbLoadingSpinnerModule,
        CbSelectListModule,
        CommonModule,
        FormsModule,
        InfiniteScrollModule,
        MatButtonModule,
        MatCardModule,
        MatCardModule,
        MatCheckboxModule,
        MatIconModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatTableModule,
        ReactiveFormsModule,
        CbDasherModule,
        CbHubTableModule,
        CbTableModule,
        CbButtonModule,
        CbWaitForModule

    ],
    exports: [
        AllManualOrdersComponent
    ]
})
export class AllManualOrdersModule { }
