<cb-dialog class="flex-col hidden-tab-header"
           dialogHeading="Request Pre-Consent Step {{selectedTabStep + 1}}">
    <form class="flex-col flex"
          #requestPreConsentForm="ngForm">
        <mat-tab-group mat-stretch-tabs="false"
                       [dynamicHeight]="true"
                       [(selectedIndex)]="selectedTabStep">
            <mat-tab>
                <form class="flex-col flex"
                      #step1Form="ngForm">
                    <cb-request-pre-consent-plan-items [(mappedItem)]="mappedItem"></cb-request-pre-consent-plan-items>
                </form>
            </mat-tab>
            <mat-tab>
                <form class="flex-col flex"
                      #step2Form="ngForm">
                    <cb-request-preconsent-signed-scheme-upload [mappedItem]="mappedItem"
                                                                [lotId]="lotMappedItem.id"
                                                                [lotContractType]="lotMappedItem.contractType"
                                                                [childForm]="step2Form.form"
                                                                [documentsToUpload]="documentsToUpload"></cb-request-preconsent-signed-scheme-upload>
                </form>
            </mat-tab>
            <mat-tab>
                <form class="flex-col flex"
                      #step3Form="ngForm">
                    <cb-request-preconsent-plan-documents-upload [mappedItem]="mappedItem"
                                                                 [lotId]="lotMappedItem.id"
                                                                 [childForm]="step3Form.form"
                                                                 [documentsToUpload]="documentsToUpload"></cb-request-preconsent-plan-documents-upload>
                </form>
            </mat-tab>
        </mat-tab-group>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (cbClick)="back()"
                    *ngIf="canGoBack()">
                Back
            </button>
            <button mat-raised-button
                    color="primary"
                    *ngIf="canGoNext()"
                    [disabled]="!isStepComplete(step1Form.form, step2Form.form )"
                    (cbClick)="next()">
                Next
            </button>
            <button mat-raised-button
                    color="primary"
                    [disabled]="!isStepComplete(step1Form.form, step2Form.form,step3Form.form)"
                    (cbClick)="requestPreconsent()"
                    *ngIf="canSeeSave()"
                    [clickLimit]="1">
                Save
            </button>
            <button mat-raised-button
                    (click)="cancel(true)">
                Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
