import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbWaitForModule} from '@app/shared/directives/wait-for/wait-for.module';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {CbDasherModule, CbTableModule} from 'cb-hub-lib';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CbEnumToLabelModule} from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import {CbUserDateTimeModule} from '@app/shared/components/user-date-time/user-date-time.module';
import {CbButtonModule} from '@app/shared/components/button/button.module';
import {TakeoffCardComponent} from './takeoff-card.component';
import {CbDatePipeModule} from '@app/shared/pipe/date/date.module';


@NgModule({
    declarations: [
        TakeoffCardComponent
    ],
    exports: [
        TakeoffCardComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        MatTableModule,
        MatIconModule,
        CbDialogModule,
        CbEnumToLabelModule,
        CbUserDateTimeModule,
        CbButtonModule,
        CbDasherModule,
        CommonModule,
        FormsModule,
        MatInputModule,
        CbDisplayValueModule,
        MatListModule,
        CbWaitForModule,
        MatSelectModule,
        MatDatepickerModule,
        MatCardModule,
        MatIconModule,
        MatCheckboxModule,
        InfiniteScrollModule,
        MatTableModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatButtonModule,
        CbHubTableModule,
        CbTableModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        CbDatePipeModule
    ]
})

export class CbTakeoffCardModule { }

