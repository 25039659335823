import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksComponent } from './tasks/tasks.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { CbInfoMessageModule } from 'cb-hub-lib';
import { CbLoadingSpinnerModule } from 'cb-hub-lib';
import { TaskDialogComponent } from './task-dialog/task-dialog.component';
import { CbDialogModule } from '../dialog/dialog.module';
import { TaskCardComponent } from './task-card/task-card.component';
import { CloseTaskDialogComponent } from './close-task-dialog/close-task-dialog.component';
import { CbFormsModule } from '../forms/forms.module';

const COMPONENTS = [TasksComponent, TaskDialogComponent, TaskCardComponent, CloseTaskDialogComponent];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        MatInputModule,
        MatDividerModule,
        MatIconModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        MatCardModule,
        CbDialogModule,
        MatDatepickerModule,
        MatSelectModule,
        CbDisplayValueModule,
        MatExpansionModule,
        MatRadioModule,
        MatCheckboxModule,
        CbFormsModule
    ],
    exports: COMPONENTS
})

export class CbTasksModule { }
