<cb-dialog class="flex-col hidden-tab-header"
           dialogHeading="Request Working Drawing"
           (cancelDialog)="cancel(true)">
    <form class="flex-col flex"
          #requestSchemeForm="ngForm"
          *cbWaitFor="mappedItem">
        <mat-tab-group mat-stretch-tabs="false"
                       [dynamicHeight]="true"
                       [(selectedIndex)]="selectedTabStep">
            <mat-tab>
                <form class="flex-col flex"
                      #step1Form="ngForm">
                    <cb-request-working-drawing-step-one [mappedItem]="mappedItem">
                    </cb-request-working-drawing-step-one>
                </form>
            </mat-tab>
        </mat-tab-group>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (cbClick)="back()"
                    *ngIf="canGoBack()">
                Back
            </button>
            <button mat-raised-button
                    color="primary"
                    [disabled]="!isStepComplete(step1Form.form)"
                    (cbClick)="requestWorkingDrawing()"
                    *ngIf="canSeeSave()"
                    [clickLimit]="1">
                Save
            </button>
            <button mat-raised-button
                    (click)="cancel(true)">
                Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
