<cb-dialog dialogHeading="Manage Pre-Consent">
    <form #preConsentForm="ngForm">
        <mat-tab-group class="tab-container" mat-stretch-tabs="false">
            <mat-tab label="Pre-Consent Details">
                <cb-manage-pre-consent-dialog-details class="cb-margin-2x"
                                                      *cbWaitFor="data && mappedItem"
                                                      [mappedItem]="mappedItem">
                </cb-manage-pre-consent-dialog-details>
            </mat-tab>
            <mat-tab label="Pre-Consent Items">
                <cb-pre-consent-items [mappedItem]="mappedItem"
                                      [parentForm]="preConsentForm"></cb-pre-consent-items>
            </mat-tab>
            <mat-tab label="Assigned Changes">

                <!-- Add Assigned Changes Tab here -->
            </mat-tab>
        </mat-tab-group>
    </form>
    <footer class="end-center flex-row">
        <button color="primary"
                mat-raised-button
                [disabled]="preConsentForm.invalid || preConsentForm.pristine"
                (cbClick)="saveAndSetPristine(preConsentForm)">Save</button>
        <button mat-raised-button
                (click)="cancel(!preConsentForm.pristine)">Cancel</button>
    </footer>
</cb-dialog>
