import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbInfoMessageModule, CbLoadingSpinnerModule, CbSelectListModule} from 'cb-hub-lib';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {MatTabsModule} from '@angular/material/tabs';
import {BuildTypesComponent} from '@app/views/settings/pages/build-types/build-types.component';
import {BuildTypesDialogComponent} from '@app/views/settings/pages/build-types/build-types-dialog/build-types-dialog.component';
import {CbSortByModule} from '@app/shared/pipe/sort-by/sort-by.module';

@NgModule({
    declarations: [
        BuildTypesComponent,
        BuildTypesDialogComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbSelectListModule,
        MatTabsModule,
        CbSortByModule
    ],
    exports: [
        BuildTypesComponent
    ]
})
export class BuildTypesSettingsModule { }
