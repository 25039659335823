import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatChipsModule } from '@angular/material/chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule as MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbProductInputFindModule } from '@app/shared/components/forms/individual/product-input-find/product-input-find.module';
import { CbGenericQuestionsModule } from '@app/shared/components/generic-questions/generic-questions.module';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbEllipsisModule } from '@app/shared/directives/ellipsis/ellipsis.module';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbFilterModule } from '@app/shared/pipe/filter/filter.module';
import { CbSortByImpureModule } from '@app/shared/pipe/sort-by-impure/sort-by-impure.module';
import { CbTruncateModule } from '@app/shared/pipe/truncate/truncate.module';
import { CbSelectListModule } from 'cb-hub-lib';
import { DragulaModule } from 'ng2-dragula';
import { CbInfoMessageModule } from 'projects/cb-hub-lib/src/lib/components/info-message/info-message.module';
import { CbInfoPopupModule } from 'projects/cb-hub-lib/src/lib/components/info-popup/info-popup.module';
import { CbLoadingSpinnerModule } from 'projects/cb-hub-lib/src/lib/components/loading-spinner/loading-spinner.module';
import { CbTableModule } from 'projects/cb-hub-lib/src/lib/components/table/table.module';
import { ChangeOptionLineDialogComponent } from './change-option-line-dialog/change-option-line-dialog.component';
import { LotChangeAreaComponent } from './lot-change-area/lot-change-area.component';
import { LotChangeDetailsComponent } from './lot-change-details/lot-change-details.component';
import { LotChangeOptionDialogNewComponent } from './lot-change-option-dialog/lot-change-option-dialog.component';
import { LotChangeOptionComponent } from './lot-change-option/lot-change-option.component';
import { LotChangeReassignApprovalDialogComponent } from './lot-change-reassign-approval-dialog/lot-change-reassign-approval-dialog.component';
import { LotChangeReassignDialogComponent } from './lot-change-reassign-dialog/lot-change-reassign-dialog.component';
import { LotChangeViewComponent } from './lot-change-view/lot-change-view.component';
import { LotChangesActionBarComponent } from './lot-changes-action-bar/lot-changes-action-bar.component';
import { LotChangesListComponent } from './lot-changes-list/lot-changes-list.component';
import { LotChangesComponent } from './lot-changes/lot-changes.component';
import { LotCreateChangeDialogComponent } from './lot-create-change-dialog/lot-create-change-dialog.component';
import { LotGenerateVariationDialogComponent } from './lot-generate-variation-dialog/lot-generate-variation-dialog.component';
import { LotSubmitChangesDialogComponent } from './lot-submit-changes-dialog/lot-submit-changes-dialog.component';
import { LotVariationChangesDialogComponent } from './lot-variation-changes-dialog/lot-variation-changes-dialog.component';
import { LotVariationDetailsDialogComponent } from './lot-variation-details-dialog/lot-variation-details-dialog.component';
import { LotVariationReuploadDialogComponent } from './lot-variation-reupload-dialog/lot-variation-reupload-dialog.component';
import { LotVariationViewComponent } from './lot-variation-view/lot-variation-view.component';
import { LotVariationsActionBarComponent } from './lot-variations-action-bar/lot-variations-action-bar.component';
import { LotVariationsListComponent } from './lot-variations-list/lot-variations-list.component';
import { LotVariationsComponent } from './lot-variations/lot-variations.component';
import { NonSimChangeOptionLineDialogComponent } from './non-sim-change-option-line-dialog/non-sim-change-option-line-dialog.component';
import { ScheduleItemsAddCreditTableComponent } from './schedule-items-add-credit-table/schedule-items-add-credit-table.component';
import { SpecItemsAddCreditTableComponent } from './spec-items-add-credit-table/spec-items-add-credit-table.component';

@NgModule({

    exports: [
        ChangeOptionLineDialogComponent,
        LotSubmitChangesDialogComponent,
        LotChangeOptionDialogNewComponent,
        LotChangeReassignDialogComponent,
        LotCreateChangeDialogComponent,
        LotChangeReassignDialogComponent,
        LotChangeReassignApprovalDialogComponent,
        NonSimChangeOptionLineDialogComponent,
        LotVariationDetailsDialogComponent,
        LotVariationChangesDialogComponent,
        LotVariationReuploadDialogComponent,
        LotGenerateVariationDialogComponent,
        LotChangeAreaComponent
    ],

    declarations: [
        ChangeOptionLineDialogComponent,
        LotChangesComponent,
        LotChangesActionBarComponent,
        LotChangesListComponent,
        LotChangeViewComponent,
        LotVariationsComponent,
        LotChangeDetailsComponent,
        LotCreateChangeDialogComponent,
        LotSubmitChangesDialogComponent,
        LotChangeOptionComponent,
        LotChangeReassignDialogComponent,
        LotChangeReassignApprovalDialogComponent,
        LotChangeOptionDialogNewComponent,
        ScheduleItemsAddCreditTableComponent,
        SpecItemsAddCreditTableComponent,
        LotVariationViewComponent,
        LotVariationsActionBarComponent,
        LotVariationsListComponent,
        NonSimChangeOptionLineDialogComponent,
        LotVariationDetailsDialogComponent,
        LotVariationReuploadDialogComponent,
        LotVariationChangesDialogComponent,
        LotGenerateVariationDialogComponent,
        LotChangeAreaComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        CbFormsModule,
        CbClickModule,
        CbProductInputFindModule,
        CbDisplayValueModule,
        DragulaModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatTabsModule,
        CbTabRouteModule,
        MatTooltipModule,
        MatIconModule,
        CbInfoMessageModule,
        MatListModule,
        MatDividerModule,
        CbTruncateModule,
        CbEnumToLabelModule,
        CbCurrencyModule,
        CbDocumentsModule,
        CbWaitForModule,
        CbGenericQuestionsModule,
        MatInputModule,
        CbSelectListModule,
        CbLoadingSpinnerModule,
        CbDialogModule,
        MatMenuModule,
        MatRadioModule,
        CbTableModule,
        CbFilterModule,
        MatAutocompleteModule,
        CbUserDateTimeModule,
        MatDialogModule,
        CbEllipsisModule,
        CbDocumentsModule,
        CbInfoPopupModule,
        MatSelectModule,
        MatChipsModule,
        MatSlideToggleModule,
        CbSortByImpureModule
    ]
})

export class LotChangesModule { }
