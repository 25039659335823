<cb-dialog>
    <form class="flex-col flex"
          #documentTypeForm="ngForm">
        <cb-input type="text"
                  name="documentType"
                  label="Document Type"
                  [required]="true"
                  [(ngModel)]="mappedItem.label">
        </cb-input>
        <div class="start-center flex-row">
            <div class="flex-col lt-md-100 gt-md-33">
                <cb-select label="Code Name"
                           name="codeName"
                           [(ngModel)]="mappedItem.codeName"
                           [options]="documentTypeCodes">
                </cb-select>
                <div class="flex-col"
                     style="margin-top: -16px;">
                    <span class="mat-caption cb-error"
                          *ngIf="showOldCode()">
                        Code name does not exist
                    </span>
                </div>
            </div>
            <cb-info-popup tooltip="This is only required if the code needs to access this document type programmatically.">
            </cb-info-popup>
            <span class="mat-body-1 cb-error"
                  *ngIf="showOldCode()">
                {{originalData.codeName}}
            </span>
        </div>
        <cb-text-area class="cb-margin-top-2x"
                      name="description"
                      label="Description"
                      [required]="true"
                      [(ngModel)]="mappedItem.description"
                      [maxlength]="100">
        </cb-text-area>
        <cb-input type="number"
                  name="maximumFileSizeInMegaBytes"
                  label="Maximum File Size (MB)"
                  [required]="true"
                  [(ngModel)]="mappedItem.maximumFileSizeInMegaBytes">
        </cb-input>
        <cb-checkbox name="isActive"
                     label="Is Active"
                     [(ngModel)]="mappedItem.isActive">
        </cb-checkbox>
        <cb-selection-list name="allowedfileTypes"
                           [(ngModel)]="selectedFileTypes"
                           [options]="fileTypes"
                           [required]="true"
                           label="File Types"
                           checkboxPosition="before">
        </cb-selection-list>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="isSaveDisabled(documentTypeForm)">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
