import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbHistoryModule } from '@app/shared/components/history/history.module';
import { CbNotesModule } from '@app/shared/components/notes/notes.module';
import { CbPopupTipModule } from '@app/shared/components/popup-tip/popup-tip.module';
import { CbSortableHeaderCellModule } from '@app/shared/components/sortable-header-cell/sortable-header-cell.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbInfoMessageModule, CbTableModule } from 'cb-hub-lib';
import { BusinessAccountAddAccountHoldDialogComponent } from './business-account-add-account-hold-dialog/business-account-add-account-hold-dialog.component';
import { BusinessAccountApprovalReasonComponent } from './business-account-approval-reason-dialog/business-account-approval-reason-dialog.component';
import { BusinessAccountCataloguesComponent } from './business-account-catalogues/business-account-catalogues.component';
import { BusinessAccountChildAccountsComponent } from './business-account-child-accounts/business-account-child-accounts.component';
import { BusinessAccountCloseAccountDialogComponent } from './business-account-close-account-dialog/business-account-close-account-dialog.component';
import { BusinessAccountContactRoleDialogComponent } from './business-account-contact-role-dialog/business-account-contact-role-dialog.component';
import { BusinessAccountContactRolesDialogComponent } from './business-account-contact-roles-dialog/business-account-contact-roles-dialog.component';
import { BusinessAccountContactsComponent } from './business-account-contacts/business-account-contacts.component';
import { BusinessAccountDetailsComponent } from './business-account-details/business-account-details.component';
import { BusinessAccountEditFinancialInfoDialogComponent } from './business-account-edit-financial-info-dialog/business-account-edit-financial-info-dialog.component';
import { BusinessAccountLocationPickerComponent } from './business-account-location-picker/business-account-location-picker.component';
import { BusinessAccountLocationsComponent } from './business-account-locations/business-account-locations.component';
import { BusinessAccountManageLocationsDialogComponent } from './business-account-manage-locations-dialog/business-account-manage-locations-dialog.component';
import { BusinessAccountOrdersComponent } from './business-account-orders/business-account-orders.component';
import { BusinessAccountQuotesComponent } from './business-account-quotes/business-account-quotes.component';
import { BusinessAccountRequestSevenDaysDialogComponent } from './business-account-request-seven-days-dialog/business-account-request-seven-days-dialog.component';
import { BusinessAccountStatusComponent } from './business-account-status/business-account-status.component';
import { BusinessAccountViewComponent } from './business-account-view/business-account-view.component';
import { BusinessAccountsComponent } from './business-accounts.component';

@NgModule({
    declarations: [
        BusinessAccountsComponent,
        BusinessAccountLocationPickerComponent,
        BusinessAccountViewComponent,
        BusinessAccountChildAccountsComponent,
        BusinessAccountOrdersComponent,
        BusinessAccountQuotesComponent,
        BusinessAccountCataloguesComponent,
        BusinessAccountLocationsComponent,
        BusinessAccountManageLocationsDialogComponent,
        BusinessAccountContactsComponent,
        BusinessAccountContactRolesDialogComponent,
        BusinessAccountContactRoleDialogComponent,
        BusinessAccountDetailsComponent,
        BusinessAccountEditFinancialInfoDialogComponent,
        BusinessAccountRequestSevenDaysDialogComponent,
        BusinessAccountAddAccountHoldDialogComponent,
        BusinessAccountCloseAccountDialogComponent,
        BusinessAccountStatusComponent,
        BusinessAccountApprovalReasonComponent,
    ],
    imports: [
        CommonModule,
        CbHubTableModule,
        CbTableModule,
        CbSortableHeaderCellModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        CbFormsModule,
        CbInfoMessageModule,
        CbDialogModule,
        MatCardModule,
        CbDatePipeModule,
        MatRadioModule,
        MatTooltipModule,
        CbUserDateTimeModule,
        MatTabsModule,
        CbHistoryModule,
        MatExpansionModule,
        CbEnumToLabelModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatChipsModule,
        CbWaitForModule,
        CbNotesModule,
        MatCheckboxModule,
        CbDocumentsModule,
        CbCurrencyModule,
        MatMenuModule,
        MatDividerModule,
        CbDisplayValueModule,
        CbPopupTipModule,
        RouterModule.forRoot([
            {path: 'business-accounts/edit/:id', redirectTo: 'business-accounts/edit/:id/summary', pathMatch: 'full'},
            {
                component: BusinessAccountsComponent,
                path: 'business-accounts',
                data: {breadcrumb: 'Business Accounts'},
            },
            {
                component: BusinessAccountViewComponent,
                path: 'business-accounts/edit/:id/:tabName',
                data: {
                    breadcrumb: 'Edit Business Account',
                    breadcrumbPredecessors: [
                        {label: 'Business Accounts', url: '/business-accounts'}
                    ]
                },
            },
            {
                component: BusinessAccountViewComponent,
                path: 'business-accounts/request',
                data: {
                    breadcrumb: 'Request Business Account',
                    breadcrumbPredecessors: [
                        {label: 'Business Accounts', url: '/business-accounts'}
                    ]
                },
            },
            {
                component: BusinessAccountViewComponent,
                path: 'business-accounts/create',
                data: {
                    breadcrumb: 'Create Business Account',
                    breadcrumbPredecessors: [
                        {label: 'Business Accounts', url: '/business-accounts'}
                    ]
                },
            }
        ])
    ]
})

export class BusinessAccountsModule { }
