<div class="cb-padding flex-col flex">
    <mat-card *ngIf="!isPopUp"
              class="cb-margin-bottom-2x start-center flex-row">
        <form class="cb-margin-right-3x flex-col flex">
            <cb-input label="Keywords"
                      name="searchText"
                      [(ngModel)]="searchText"
                      (change)="queryUpdate.next(null)"></cb-input>
        </form>
        <div class="flex-col">
            <button mat-raised-button
                    color="accent"
                    [disabled]="!hasUnread"
                    (click)="markAllAsRead()">
                <mat-icon>markunread</mat-icon>
                &nbsp;Mark All Read
            </button>
        </div>
    </mat-card>
    <mat-card class="scroll-container"
              [cbInfiniteScroll]="listContainer"
              [fetch]="fetchResults()"
              [queryUpdated]="queryUpdate"
              [enabled]="searchEnabled$"
              [(results)]="results"
              [(currentPage)]="currentPage">
        <div #listContainer
             class="list-container flex-wrap xs-column flex">
            <mat-list class="flex">
                <mat-list-item *ngFor="let notification of results">
                    <div class="flex flex-row start-center">
                        <mat-icon matListItemIcon class="notification-icon cb-margin-left-2x"
                                  [ngClass]="{'mat-accent' : !notification.isRead}">{{ getNotifcationIcon(notification) }}
                        </mat-icon>
                        <div class="flex-col flex"
                             (click)="goToItem(notification)">
                            <h3 matListItemTitle
                                [ngStyle]="{'font-weight': notification.isRead ? '400' : 'bold'}">
                                {{ notification.subject }}
                            </h3>
                            <h2 matListItemLine class="wrap">{{ notification.body }}</h2>
                            <p class="mat-caption cb-grey" matListItemLine>
                                <cb-user-date-time-new [label]="'Received at'"
                                                       [date]="notification.date"></cb-user-date-time-new>
                            </p>
                        </div>
                        <button *ngIf="!isPopUp"
                                mat-mini-fab
                                color="accent"
                                [disabled]="notification.isRead"
                                (click)="markAsRead(notification)">
                            <mat-icon>markunread</mat-icon>
                        </button>
                    </div>
                </mat-list-item>
                <mat-list-item *ngIf="!results?.length">
                    <mat-icon matListItemIcon>notifications_none</mat-icon>
                    <div class="flex-col">
                        <p *ngIf="showUnreadOnly"
                           class="mat-body-1">You have no unread notifications.</p>
                        <p *ngIf="!showUnreadOnly"
                           class="mat-body-1">You have no notifications.</p>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </mat-card>
</div>
