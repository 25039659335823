import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {TagsComponent} from './tags.component';
import {TagsDialogComponent} from './components/tags-dialog/tags-dialog.component';
import {CbInfoMessageModule, CbLoadingSpinnerModule} from 'cb-hub-lib';

@NgModule({
    declarations: [
        TagsComponent,
        TagsDialogComponent
    ],
    imports: [

        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbDisplayValueModule
    ],
    exports: [
        TagsComponent
    ]
})
export class TagsSettingsModule { }
