<div class="flex flex-row"
     *ngIf="mappedItem">
    <mat-tab-group class="flex"
                   #tabGroup
                   (selectedTabChange)="tabChanged($event)"
                   cbDefaultTabId="details"
                   cbTabUrlParam="cbTabRouteId"
                   mat-stretch-tabs="false"
                   [cbTabRoute]="PRODUCT_TAB_FULL_ROUTE">
        <mat-tab label="Item Details"
                 cbTabRouteId="details"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-product-details-tab></cb-product-details-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Documents"
                 [disabled]="true"
                 cbTabRouteId="documents"
                 cbNestedTabLevels="0">
        </mat-tab>
        <mat-tab label="Item Usage"
                 [disabled]="!canViewProductUsage()"
                 cbTabRouteId="item-usage"
                 cbNestedTabLevels="1">
            <ng-template matTabContent>
                <cb-item-usage-tab [mappedItem]="mappedItem"></cb-item-usage-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="History"
                 [disabled]="true"
                 cbTabRouteId="history"
                 cbNestedTabLevels="0">
        </mat-tab>
    </mat-tab-group>
    <cb-side-bar class="cb-margin flex-col flex-20">
        <div class="flex-col">
            <div class="start-center flex-row">
                <mat-icon class="cb-margin-right"
                          [ngClass]="getHtmlClassFromStatus()">
                    label
                </mat-icon>
                <h3 class="mat-title cb-margin-nil"
                    id="product-status"
                    [ngClass]="getHtmlClassFromStatus()">
                    {{mappedItem.productType | cbEnumToLabel: PRODUCT_TYPE_ENUM}} {{getStatusText()}}
                </h3>
            </div>
            <mat-divider class="divider"
                         style="position: static"></mat-divider>
            <h4 class="mat-subheading-1 cb-margin-top cb-margin-bottom-nil">{{mappedItem.code}}</h4>
            <h4 class="mat-subheading-1">{{mappedItem.name}}</h4>
            <span layout-margin></span>
            <cb-display-value-new *ngIf="mappedItem.status === OFFERING_STATUS_ENUM.TemporarilyUnavailable && mappedItem.replacementOfferingId"
                                  label="Temporarily Replaced With">
                <span class="start-center flex-row">
                    {{mappedItem?.replacementOffering?.name}}
                    <mat-icon style="height: 2px; margin-top: -20px; cursor: pointer"
                              (click)="viewProduct(mappedItem.replacementOfferingId)">pageview</mat-icon>
                </span>
            </cb-display-value-new>
            <cb-display-value-new *ngIf="mappedItem.status === OFFERING_STATUS_ENUM.PermanentlyUnavailable && mappedItem.replacementOfferingId"
                                  label="Permanently Replaced With">
                <span class="start-center flex-row">
                    {{mappedItem?.replacementOffering?.name}}
                    <mat-icon style="height: 2px; margin-top: -20px; cursor: pointer"
                              (click)="viewProduct(mappedItem.replacementOfferingId)">pageview</mat-icon>
                </span>
            </cb-display-value-new>
            <span class="flex"></span>
            <div class="flex-col flex"
                 *ngIf="true">
                <button class="cb-margin-bottom"
                        mat-raised-button
                        color="primary"
                        [disabled]="!canEditProduct()"
                        *ngIf="isView() && canEdit()"
                        (cbClick)="editProduct()">
                    Edit Item
                </button>
                <button class="cb-margin-bottom"
                        mat-raised-button
                        color="primary"
                        [disabled]="!canManageAvailability()"
                        *ngIf="isView()"
                        (cbClick)="openManageAvailability()">
                    Manage Availability
                </button>
                <button class="cb-margin-bottom"
                        mat-raised-button
                        color="primary"
                        [disabled]="!canManageImages()"
                        *ngIf="isView() && canEdit()"
                        (cbClick)="openManageImages()">
                    Manage Images
                </button>
                <button class="cb-margin-bottom"
                        mat-raised-button
                        color="primary"
                        [disabled]="!canManageRates() || mappedItem.isCompositeItem"
                        *ngIf="isView() && canEdit() && mappedItem.isNotBundle()"
                        (cbClick)="addRate()">
                    Add Rate
                </button>
            </div>
            <div class="cb-margin-top flex-col">
                <cb-user-date-time-new label="Created by"
                                       [date]="mappedItem.createdDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"
                                       [userFullName]="mappedItem.getUserFullName(mappedItem.createdBy)">
                </cb-user-date-time-new>
                <cb-user-date-time-new label="Updated by"
                                       [date]="mappedItem.updatedDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"
                                       [userFullName]="mappedItem.getUserFullName(mappedItem.updatedBy)">
                </cb-user-date-time-new>
            </div>
        </div>
    </cb-side-bar>
</div>
