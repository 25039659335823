import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ISpecTemplateDto, ISpecTemplateFilter, ISpecTemplateMappedItem, SpecTemplateLogicService, SpecTemplateMappedItem } from '@app/logic/spec-template';
import { SpecTemplateDialogComponent } from './spec-template-dialog/spec-template-dialog.component';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import moment from 'moment';
import { IProjectDetailsDto, ISkinnySpecTemplateDto } from '@classictechsolutions/hubapi-transpiled-enums';


@Component({
    selector: 'cb-spec-templates',
    templateUrl: './spec-templates.component.html',
    styleUrls: ['./spec-templates.component.scss']
})
export class SpecTemplatesComponent extends BaseSimpleListViewDirective<ISkinnySpecTemplateDto, SpecTemplateLogicService> {

    private currentFilters: ISpecTemplateFilter;

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        protected readonly specTemplateLogicService: SpecTemplateLogicService,
        protected readonly navigationService: NavigationService,
    ) {
        super(
            cbDialog,
            specTemplateLogicService,
            SpecTemplateDialogComponent,
            'Specification Template',
            SpecTemplateMappedItem,
            permissionsPermissions,
            false,
        );
    }

    public search(filters?: ISpecTemplateFilter): void {
        this.currentFilters = filters;
        this.specTemplateLogicService.search(filters).subOnce((result: ISkinnySpecTemplateDto[]) => {
            this.handleSearchResults(result);
        });
    }

    public sortSearchResults(searchResults: ISkinnySpecTemplateDto[]): ISkinnySpecTemplateDto[] {
        return searchResults.sort((a, b) => moment.utc(a.updatedDate).diff(moment.utc(b.updatedDate)));
    }

    public viewTemplateClicked(id: number, isScheduleSpecTemplate: boolean): void {
        if (isScheduleSpecTemplate) {
            this.navigationService.navigate([`/spec-schedule-templates/view/${id}`]);
        } else {
            this.navigationService.navigate([`/spec-templates/view/${id}/template`]);
        }
    }

    // NOTE: This has been written because the base version of this function takes in a mapped item
    public editItemClickedAlternative(skinnySpecTemplateDto: ISkinnySpecTemplateDto, loadMappedItemFromDb = false): void {
        const specTemplateDto: ISpecTemplateDto = this.mapSkinnySpecTemplateToSpecTemplateDto(skinnySpecTemplateDto);
        const mappedItem: ISpecTemplateMappedItem = this.logicService.$createMappedItem(specTemplateDto, this.mappedItemCtor);

        super.openEditDialogWithMappedItem(loadMappedItemFromDb, mappedItem);
    }

    public duplicateItemClicked(skinnySpecTemplateDto: ISkinnySpecTemplateDto): MatDialogRef<SpecTemplateDialogComponent> {
        const specTemplateDto: ISpecTemplateDto = this.mapSkinnySpecTemplateToSpecTemplateDto(skinnySpecTemplateDto);
        const newMappedItem = this.logicService.$createMappedItem(specTemplateDto, SpecTemplateMappedItem);
        newMappedItem.id = undefined;
        newMappedItem.name = `Copy of ${newMappedItem.name}`;
        newMappedItem.baseTemplateId = skinnySpecTemplateDto.id;

        const dialog = this.cbDialog.open(SpecTemplateDialogComponent, {
            data: {
                dialogHeading: 'Duplicate Specification Template',
                mappedItem: newMappedItem,
                baseTemplateName: skinnySpecTemplateDto.name
            }
        });
        dialog.afterClosed().subOnce((item: SpecTemplateMappedItem) => this.handleNewItem(item));
        return dialog;
    }

    // Mapping as a result of #39775
    private mapSkinnySpecTemplateToSpecTemplateDto(skinnySpecTemplateDto: ISkinnySpecTemplateDto): ISpecTemplateDto {
        const project: IProjectDetailsDto = {
            contacts: [],
            currentStage: undefined,
            projectLogoUrl: '',
            releaseStages: [],
            teamMembers: [],
            ...skinnySpecTemplateDto.project
        };

        return {
            createdByName: skinnySpecTemplateDto.createdByName,
            createdDate: skinnySpecTemplateDto.createdDate,
            id: skinnySpecTemplateDto.id,
            isActive: skinnySpecTemplateDto.isActive,
            isRegional: skinnySpecTemplateDto.isRegional,
            isScheduleSpecTemplate: skinnySpecTemplateDto.isScheduleSpecTemplate,
            name: skinnySpecTemplateDto.name,
            project,
            projectId: project.id,
            regionIds: skinnySpecTemplateDto.regionIds,
            tierRange: skinnySpecTemplateDto.tierRange,
            specTemplateGroups: [],
            updatedByName: skinnySpecTemplateDto.updatedByName,
            updatedDate: skinnySpecTemplateDto.updatedDate
        };
    }

    protected handleNewItem(item: SpecTemplateMappedItem): void {
        this.search(this.currentFilters);
    }

    public handleEditItem = (item: SpecTemplateMappedItem): void => {
        this.search(this.currentFilters);
    };
}
