<cb-lot-resource-consent-action-bar class="cb-margin-left cb-margin-top cb-margin-bottom flex"
                                    [resourceConsent]="resourceConsent"
                                    [hasResourceConsentDocument]="hasResourceConsentDocument"
                                    (reloadLotMappedItem)="reloadLotMappedItem.emit()">
</cb-lot-resource-consent-action-bar>
<div class="cb-margin-left flex-row flex">
    <mat-card style="overflow-y: auto"
              class="cb-margin-right flex-col md-25 sm-33 xs-50 flex-20">
        <mat-card-header>
            <mat-card-title class="title">{{resourceConsent.consentTypeLabel}}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="flex-col">
            <cb-display-value-new label="Council"
                                  [value]="resourceConsent.councilName"></cb-display-value-new>
            <cb-display-value-new label="Consent Number"
                                  [value]="resourceConsent.consentNumber"></cb-display-value-new>
            <cb-display-value-new label="Consent Number"
                                  [value]="resourceConsent.consentNumber"></cb-display-value-new>
            <cb-display-value-new label="Assigned To"
                                  [value]="resourceConsent.assignedToUserName"></cb-display-value-new>
            <cb-display-value-new label="Status"
                                  [value]="resourceConsent.consentStatusLabel"></cb-display-value-new>
            <cb-elapsed-time label="Elapsed Time"
                             [elapsedTime]="elapsedTime"
                             [counting]="resourceConsent.consentStatus === RESOURCE_CONSENT_STATUS_ENUM.InProgress">
            </cb-elapsed-time>
            <cb-user-date-time-new *ngIf="resourceConsent.createdDate && resourceConsent.createdByName"
                                   label="Created"
                                   [date]="resourceConsent.createdDate"
                                   [userFullName]="resourceConsent.createdByName"
                                   [floatingLabel]="true"></cb-user-date-time-new>
            <cb-user-date-time-new *ngIf="resourceConsent.updatedDate && resourceConsent.updatedByName"
                                   label="Updated"
                                   [date]="resourceConsent.updatedDate"
                                   [userFullName]="resourceConsent.updatedByName"
                                   [floatingLabel]="true"></cb-user-date-time-new>
        </mat-card-content>
    </mat-card>
    <mat-card class="main-tabs-card flex-row flex">
        <mat-tab-group class="card-tab-group"
                       mat-stretch-tabs="false"
                       [(selectedIndex)]="selectedTabIndex">
            <mat-tab label="Details">
                <cb-consent-details *cbWaitFor="resourceConsent"
                                    [mappedItem]="resourceConsent"
                                    [numberOfRfis]="councilRfiCount"></cb-consent-details>
            </mat-tab>
            <mat-tab label="Documents ({{documentCount}})">
                <cb-lot-consent-documents [mappedItem]="resourceConsent"
                                          [systemArea]="lotConsentViewService?.systemArea$$ | async"
                                          [edit]="!resourceConsent?.isComplete"
                                          (documentCount)="documentCount = $event"
                                          (hasResourceConsentDocument)="hasResourceConsentDocument = $event">
                </cb-lot-consent-documents>
            </mat-tab>
            <mat-tab label="RFIs ({{councilRfiCount}})">
                <cb-lot-consent-rfis [mappedItem]="resourceConsent"
                                     [lotDto]="lotDto"
                                     (councilRfiCount)="councilRfiCount = $event"></cb-lot-consent-rfis>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>
