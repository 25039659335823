<ng-container *cbWaitFor="loaded && lotSpec">
    <mat-card class="cb-margin-top flex gt-sm-row gt-sm-start center lt-md-column">
        <div class="start-center flex-wrap flex"
             *ngIf="!fullscreen">
            <cb-display-value-new class="cb-margin-right-2x"
                                  label="Lot Spec Version"
                                  [value]="lotSpecVersion">
            </cb-display-value-new>
            <cb-display-value-new label="Last Applied Spec Template"
                                  [value]="specTemplateName">
            </cb-display-value-new>
        </div>
        <div *ngIf="fullscreen"
             class="cb-margin-top flex">
            <form class="flex gt-sm-row wrap gt-sm-start center lt-md-column"
                  #actionBarForm="ngForm">
                <div class="start-center flex-row">
                    <mat-icon class="cb-margin-right">search</mat-icon>
                    <cb-input class="cb-margin-right"
                              label="Search"
                              name="search"
                              [(ngModel)]="userCacheItem.data.keyword"></cb-input>
                    <cb-toggle-list-filter-menu class="gt-sm-hide"
                                                *ngIf="lotSpec && lotSpec.lotSpecScheduleItems"
                                                (clearFilters)="clearFilters()"
                                                [isSpecSchedule]="true"
                                                [(listViewEnabled)]="userCacheItem.data.enableListView"
                                                [(isIncompleteOnly)]=" isIncompleteItemsOnly"
                                                (isIncompleteOnlyChange)="isIncompleteOnlyChange($event)">
                    </cb-toggle-list-filter-menu>
                    <cb-toggle-list-filter-menu class="gt-sm-hide"
                                                *ngIf="!(lotSpec && lotSpec.lotSpecScheduleItems)"
                                                (clearFilters)="clearFilters()"
                                                [isSpecSchedule]="false"
                                                [(listViewEnabled)]="userCacheItem.data.enableListView">
                    </cb-toggle-list-filter-menu>
                </div>

                <!-- Hide this on new spec Template  -->
                <cb-select-list *ngIf="lotSpec && lotSpec?.items?.length > 0"
                                class="cb-margin-right gt-sm-flex flex"
                                label="Lot Spec Item Type"
                                [allOptions]="filteredSpecItemTypes.value"
                                [multiple]="true"
                                [(value)]="userCacheItem.data.selectedlotSpecItemEntityTypes">
                </cb-select-list>

                <!-- Hide this on old spec Template  -->
                <cb-select-list *ngIf="lotSpec && lotSpec?.lotSpecScheduleItems?.length > 0"
                                class="cb-margin-right gt-sm-flex flex"
                                label="Tags"
                                [allOptions]="filteredTags.value"
                                [multiple]="true"
                                [(value)]="userCacheItem.data.selectedTags">
                </cb-select-list>
                <cb-select-list class="cb-margin-right gt-sm-flex flex"
                                label="Cost Type"
                                [allOptions]="filteredCostTypes.value"
                                [multiple]="true"
                                [(value)]="userCacheItem.data.selectedCostTypes">
                </cb-select-list>
                <cb-select-list class="cb-margin-right gt-sm-flex flex"
                                label="House Areas"
                                [allOptions]="filteredHouseAreas.value"
                                [multiple]="true"
                                [(value)]="userCacheItem.data.selectedHouseAreas">
                </cb-select-list>
                <cb-toggle-list-filter-menu class="cb-margin-right lt-md-hide"
                                            *ngIf="lotSpec && lotSpec.lotSpecScheduleItems"
                                            (clearFilters)="clearFilters()"
                                            [isSpecSchedule]="true"
                                            [(listViewEnabled)]="userCacheItem.data.enableListView"
                                            [(isIncompleteOnly)]=" isIncompleteItemsOnly"
                                            (isIncompleteOnlyChange)="isIncompleteOnlyChange($event)">
                </cb-toggle-list-filter-menu>
                <cb-toggle-list-filter-menu class="cb-margin-right lt-md-hide"
                                            [isSpecSchedule]="false"
                                            *ngIf="!(lotSpec && lotSpec.lotSpecScheduleItems)"
                                            (clearFilters)="clearFilters()"
                                            [(listViewEnabled)]="userCacheItem.data.enableListView">
                </cb-toggle-list-filter-menu>
            </form>
        </div>
        <div class="flex-row gt-sm-row wrap gt-sm-start center lt-md-column">
            <div class="end-end flex-none">
                <ng-container *ngIf="!isSkinnyView">
                    <cb-lot-spec-actions-menu *ngIf="lotSpec"
                                              [lotSpec]="lotSpec"
                                              (lotSpecChange)="reloadLotSpec()"
                                              [lotMappedItem]="lotMappedItem"
                                              [houseAreas]="fullHouseAreas">
                    </cb-lot-spec-actions-menu>
                    <button class="cb-margin-right"
                            mat-raised-button
                            color="primary"
                            (click)="applySpecTemplate()"
                            [disabled]="isApplyLotSpecDisabled()">
                        Apply Spec Template
                    </button>
                    <cb-lot-spec-reports-menu *ngIf="lotMappedItem"
                                              [lot]="lotMappedItem"></cb-lot-spec-reports-menu>
                </ng-container>
                <button *ngIf="isSkinnyView"
                        class="cb-margin-right"
                        mat-raised-button
                        color="primary"
                        (click)="generateReport()">
                    <mat-icon>insert_drive_file</mat-icon>
                    Generate Report
                </button>
                <button class="cb-margin-left"
                        mat-raised-button
                        [disabled]="totalHouseAreaItems < 1"
                        (click)="expandCollapseAll()">
                    <span class="start-center flex-row"
                          *ngIf="!allExpanded">
                        <span>Expand All&nbsp;</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </span>
                    <span class="start-center flex-row"
                          *ngIf="allExpanded">
                        <span>Collapse All&nbsp;</span>
                        <mat-icon>keyboard_arrow_up</mat-icon>
                    </span>
                </button>
                <button class="cb-margin-left"
                        ngClass.lt-xl="cb-margin-top"
                        mat-icon-button
                        *ngIf="!fullscreen"
                        (click)="viewFullscreen()">
                    <mat-icon>fullscreen</mat-icon>
                </button>
            </div>
        </div>
    </mat-card>
    <mat-card *ngIf="!isSkinnyView && !fullscreen"
              class="cb-margin-top">
        <form class="flex gt-sm-row wrap gt-sm-start center lt-md-column"
              #actionBarForm="ngForm">
            <div class="start-center flex-row">
                <mat-icon class="cb-margin-right">search</mat-icon>
                <cb-input class="cb-margin-right lt-md-flex"
                          label="Search"
                          name="search"
                          [(ngModel)]="userCacheItem.data.keyword"></cb-input>
                <cb-toggle-list-filter-menu class="gt-sm-hide"
                                            *ngIf="lotSpec && lotSpec.lotSpecScheduleItems"
                                            (clearFilters)="clearFilters()"
                                            [isSpecSchedule]="true"
                                            [(listViewEnabled)]="userCacheItem.data.enableListView"
                                            [(isIncompleteOnly)]=" isIncompleteItemsOnly"
                                            (isIncompleteOnlyChange)="isIncompleteOnlyChange($event)">
                </cb-toggle-list-filter-menu>
                <cb-toggle-list-filter-menu class="gt-sm-hide"
                                            *ngIf="!(lotSpec && lotSpec.lotSpecScheduleItems)"
                                            (clearFilters)="clearFilters()"
                                            [isSpecSchedule]="false"
                                            [(listViewEnabled)]="userCacheItem.data.enableListView">
                </cb-toggle-list-filter-menu>
            </div>

            <!-- Hide this on new spec Template  -->
            <cb-select-list *ngIf="lotSpec && lotSpec?.items?.length > 0"
                            class="cb-margin-right gt-sm-flex"
                            label="Lot Spec Item Type"
                            [allOptions]="filteredSpecItemTypes.value"
                            [multiple]="true"
                            [(value)]="userCacheItem.data.selectedlotSpecItemEntityTypes">
            </cb-select-list>

            <!-- Hide this on old spec Template  -->
            <cb-select-list *ngIf="lotSpec && lotSpec?.lotSpecScheduleItems?.length > 0"
                            class="cb-margin-right gt-sm-flex"
                            label="Tags"
                            [allOptions]="filteredTags.value"
                            [multiple]="true"
                            [(value)]="userCacheItem.data.selectedTags">
            </cb-select-list>
            <cb-select-list class="cb-margin-right gt-sm-flex"
                            label="Cost Type"
                            [allOptions]="filteredCostTypes.value"
                            [multiple]="true"
                            [(value)]="userCacheItem.data.selectedCostTypes">
            </cb-select-list>
            <cb-select-list class="cb-margin-right gt-sm-flex"
                            label="House Areas"
                            [allOptions]="filteredHouseAreas.value"
                            [multiple]="true"
                            [(value)]="userCacheItem.data.selectedHouseAreas">
            </cb-select-list>
            <cb-toggle-list-filter-menu class="lt-md-hide"
                                        *ngIf="lotSpec && lotSpec.lotSpecScheduleItems"
                                        (clearFilters)="clearFilters()"
                                        [isSpecSchedule]="true"
                                        [(listViewEnabled)]="userCacheItem.data.enableListView"
                                        [(isIncompleteOnly)]=" isIncompleteItemsOnly"
                                        (isIncompleteOnlyChange)="isIncompleteOnlyChange($event)">
            </cb-toggle-list-filter-menu>
            <cb-toggle-list-filter-menu class="lt-md-hide"
                                        *ngIf="!(lotSpec && lotSpec.lotSpecScheduleItems)"
                                        (clearFilters)="clearFilters()"
                                        [isSpecSchedule]="false"
                                        [(listViewEnabled)]="userCacheItem.data.enableListView">
            </cb-toggle-list-filter-menu>
        </form>
    </mat-card>
    <ng-container *ngIf="lotSpec && lotSpec?.items?.length > 0">
        <cb-lot-spec-items-list [expandedPanels]="expandedAreas"
                                [lotSpec]="lotSpec"
                                [houseAreaItems]="filteredSpecGroupItems.value"
                                [listViewEnabled]="userCacheItem.data.enableListView"
                                (expandedPanelsChange)="setAllExpanded()"
                                [fullscreen]="fullscreen"
                                class="cb-margin-top"></cb-lot-spec-items-list>
    </ng-container>
    <ng-container *ngIf="lotSpec && lotSpec?.lotSpecScheduleItems?.length > 0">
        <cb-lot-spec-schedule-items-list *cbWaitFor="lotSpec && expandedAreas && houseAreas"
                                         [expandedPanels]="expandedAreas"
                                         [isExpandCollapseAllClicked]="isExpandCollapseAllClicked"
                                         [houseAreaItems]="houseAreas"
                                         [lotSpec]="lotSpec"
                                         (expandedPanelsChange)="setFilteredScheduleItems($event)"
                                         (totalHouseAreaItemsChanged)="setTotalHouseAreaItems($event)"
                                         [isSkinnyView]="isSkinnyView"
                                         [fullscreen]="fullscreen"
                                         class="cb-margin-top"
                                         [isIncompleteItemsOnlySubject]="isIncompleteItemsOnlySubject.asObservable()">
        </cb-lot-spec-schedule-items-list>
    </ng-container>
</ng-container>
