<cb-dialog [dialogHeading]="isEdit() ? 'Edit Filter Attribute' : 'Add Filter Attribute'">
    <form #attributeForm="ngForm">
        <h3 class="cb-title">
            Filter Attribute
        </h3>
        <cb-input type="string"
                  label="Match Value"
                  name="matchValue"
                  [(ngModel)]="filterAttribute.matchValue"
                  [required]="true">
        </cb-input>
        <cb-input type="number" 
                  label="Sort Order"
                  name="sortOrder" 
                  [(ngModel)]="filterAttribute.sortOrder"
                  [required]="true"></cb-input>
        <cb-text-area label="Description" 
                      name="description"
                      [required]="false" 
                      [(ngModel)]="filterAttribute.description"></cb-text-area>
        <cb-checkbox label="Is Active?"
                     name="isActive"
                     [(ngModel)]="filterAttribute.isActive"></cb-checkbox>
        <cb-checkbox label="Is Regular Expression?"
                     name="isRegex"
                     [(ngModel)]="filterAttribute.isRegex"></cb-checkbox>
        <cb-checkbox label="Has prerequisite filter match requirement?"
                     name="hasPrerequisite"
                     [disabled]="!hasOtherFiltersAvailable"
                     [(ngModel)]="hasPrerequisiteRequirement"></cb-checkbox>
        <cb-select *ngIf="hasPrerequisiteRequirement && hasOtherFiltersAvailable"
                   label="Prerequisite filter name"
                   name="prerequisitefilter"
                   [options]="availablePrerequisiteFilters"
                   labelProp="name"
                   valueProp="id"
                   [(ngModel)]="filterAttribute.prerequisiteFilterMatchId"
                   [required]="true"></cb-select>
        <cb-input *ngIf="hasPrerequisiteRequirement && hasOtherFiltersAvailable"
                  type="string"
                  label="Prerequisite match value"
                  name="prerequisiteMatchValue"
                  [(ngModel)]="filterAttribute.prerequisiteFilterMatchValue"
                  [required]="true">
        </cb-input>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="attributeForm.invalid || attributeForm.pristine"
                (cbClick)="save()"
                [clickLimit]="1">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>