<cb-dialog>
    <form class="flex-col flex"
          #buidlTypeForm="ngForm">
        <mat-tab-group mat-dynamic-height
                       mat-border-bottom
                       mat-stretch-tabs="false">
            <mat-tab label="Details" >
                <ng-template [matTabContent]>
                    <div class="cb-padding-left-2x cb-padding-right-2x cb-padding-top-2x cb-padding-bottom-2x">
                        <cb-input type="text"
                                  name="code"
                                  label="Code"
                                  [required]="true"
                                  [(ngModel)]="mappedItem.code"></cb-input>
                        <div *ngIf="isCodeDuplicated()"
                             class="cb-padding-bottom-2x">
                            <mat-error>
                                <small>
                                    Build Type Code needs to be unique
                                </small>
                            </mat-error>
                        </div>
                        <cb-input type="text"
                                  name="buildType"
                                  label="Build Type"
                                  [required]="true"
                                  [(ngModel)]="mappedItem.label"></cb-input>
                        <div class="flex-row">
                            <div class="checkbox-list flex-col">
                                <span class="cb-label">
                                    Lot Types
                                </span>
                                <cb-checkbox *ngFor="let type of lotTypes"
                                             [name]="'LotType'+type.id"
                                             [label]="type.label"
                                             [ngModel]="isLotTypeChecked(type.id)"
                                             (change)="onLotTypeChanged(type.id, $event)"></cb-checkbox>
                            </div>
                        </div>
                        <cb-select *ngIf="paymentTemplates && paymentTemplates.length"
                                   label="Payment Templates"
                                   name="paymentTemplate"
                                   [options]="paymentTemplates"
                                   [ngModel]="getDefaultPaymentTemplate()"
                                   (change)="onPaymentTemplateChanged($event)"></cb-select>
                        <cb-checkbox label="Active"
                                     name="isActive"
                                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab label="Build Programme Templates">
                <ng-template [matTabContent]>
                    <div class="cb-padding-left-2x cb-padding-right-2x cb-padding-top-2x cb-padding-bottom-2x">
                        <cb-input type="text"
                                  name="code"
                                  label="Code"
                                  [readonly]="true"
                                  [ngModel]="mappedItem.code">
                        </cb-input>
                        <div class="cb-table cb-margin-top">
                            <mat-table [dataSource]="data.locationRegions">
                                <ng-container matColumnDef="region">
                                    <mat-header-cell *matHeaderCellDef>
                                        Region
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let region">
                                        {{region.name}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="buildTemplate">
                                    <mat-header-cell *matHeaderCellDef>
                                        Build Template
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let region">
                                        <cb-select [name]="'buildTemplate'+region.id"
                                                   [options]="data.buildTemplates"
                                                   [ngModel]="getDefaultBuildTemplate(region.id)"
                                                   (change)="onBuildTemplateChanged(region.id, $event)"></cb-select>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            </mat-table>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!buidlTypeForm.dirty || !buidlTypeForm.valid || isCodeDuplicated()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
