import { Component, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ProductCentreFilterLogicService } from '@app/logic/product-centre/product-centre-filter.logic.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { FormMode } from '@app/shared/enums/form';
import { IProductCentreFilterAttributeDto, IProductCentreFilterDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    attribute: IProductCentreFilterAttributeDto;
    availableFilters: IProductCentreFilterDto[];
}

@Component({
    templateUrl: './product-centre-filter-attribute-dialog.component.html',
    styleUrls: ['./product-centre-filter-attribute-dialog.component.scss'],
    providers: [
        ProductCentreFilterLogicService
    ]
})
export class ProductCentreFilterAttributeDialogComponent extends BaseDialogFormViewDirective<any, any, any> {
    public static readonly MIN_WIDTH = '45%';
    public filterAttribute: IProductCentreFilterAttributeDto;
    public availablePrerequisiteFilters: IProductCentreFilterDto[];
    public hasPrerequisiteRequirement: boolean;
    private readonly _isOtherFiltersEmpty: boolean;

    constructor(
        public readonly dialogRef: MatDialogRef<ProductCentreFilterAttributeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly toastService: ToastService,
        private readonly productCenterFilterLogicService: ProductCentreFilterLogicService
    ) {
        super(dialogRef, toastService);
        this.filterAttribute = this.data.attribute;
        this.availablePrerequisiteFilters = this.data.availableFilters ?? [];
        this._isOtherFiltersEmpty = this.availablePrerequisiteFilters.length === 0;
    }

    public ngOnInit(): void {
        this.formMode = (!this.filterAttribute.id) ? FormMode.Add : FormMode.Edit;

        this.hasPrerequisiteRequirement = !!this.filterAttribute.prerequisiteFilterMatchId;
    }

    public get hasOtherFiltersAvailable(): boolean {
        return !this._isOtherFiltersEmpty;
    }

    public save(): void {
        if (!this.hasPrerequisiteRequirement){
            this.filterAttribute.prerequisiteFilterMatchId = null;
            this.filterAttribute.prerequisiteFilterMatchValue = null;
        }
        this.dialogRef.close(this.filterAttribute);
    }
}
