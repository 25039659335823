<cb-dialog>
    <form class="flex-col flex"
          #businessAccountTypeDocsForm="ngForm">
        <cb-selection-list name="requiredDocs"
                           [(ngModel)]="mappedItem.documentTypeIds"
                           [options]="bizTypeDocTypes"
                           label="Required Docs"
                           checkboxPosition="before">
        </cb-selection-list>
        <div class="flex-col"
             *ngIf="bizTypeDocTypes && bizTypeDocTypes.length == 0">
            <p>
                No document types were found. Please ensure a document group has been setup with the code name "BIZREQ"
                and that there are
                document types available within the group.
            </p>
        </div>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="businessAccountTypeDocsForm.pristine || businessAccountTypeDocsForm.invalid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
