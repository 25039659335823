<cb-dialog dialogHeading="Stalled or Lost Sale">
    <form class="flex-col flex"
          #saleLostForm="ngForm">
        <cb-checkbox name="confirmLost"
                     label="Confirm Sale as Lost (Cannot be undone)"
                     [(ngModel)]="confirmLost"
                     *ngIf="permissions?.canConfirmSaleLost()"
                     [disabled]="isPendingLost"></cb-checkbox>
        <cb-select class="cb-margin-top"
                   label="Lost or Stalled Reason"
                   name="jobLostReason"
                   [(ngModel)]="saleLostDto.jobLostReason"
                   [required]="true"
                   [options]="jobLostReasonLookup"></cb-select>
        <mat-form-field>
            <mat-label>Comments</mat-label>
            <textarea matInput
                      name="jobLostComments"
                      [(ngModel)]="saleLostDto.jobLostComments"
                      [required]="confirmLost"></textarea>
        </mat-form-field>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    [disabled]="!saleLostForm.dirty || !saleLostForm.valid"
                    (click)="save()">Save</button>
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
