<cb-lot-building-consent-action-bar class="cb-margin-left cb-margin-top cb-margin-bottom flex"
                                    [buildingConsent]="buildingConsent"
                                    [hasBuildingConsentDocument]="hasBuildingConsentDocument"
                                    (reloadLotMappedItem)="reloadLotMappedItem.emit()">
</cb-lot-building-consent-action-bar>
<div class="cb-margin-left flex-row flex">
    <mat-card style="overflow-y: auto"
              class="cb-margin-right flex-col md-25 sm-33 xs-50 flex-20">
        <mat-card-header>
            <mat-card-title class="title">{{buildingConsent.consentTypeLabel}}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="flex-col">
            <cb-checkbox *ngIf="buildingConsent.isPrimary"
                         label="Primary Building Consent"
                         [ngModel]="buildingConsent.isPrimary"
                         [readonly]="true">
            </cb-checkbox>
            <cb-display-value-new label="Council"
                                  [value]="buildingConsent.councilName"></cb-display-value-new>
            <cb-display-value-new label="Consent Number"
                                  [value]="buildingConsent.consentNumber"></cb-display-value-new>
            <cb-display-value-new label="Consent Number"
                                  [value]="buildingConsent.consentNumber"></cb-display-value-new>
            <cb-display-value-new label="Assigned To"
                                  [value]="buildingConsent.assignedToUserName"></cb-display-value-new>
            <cb-display-value-new label="Status"
                                  [value]="buildingConsent.consentStatusLabel"></cb-display-value-new>
            <cb-elapsed-time label="Elapsed Time"
                             [elapsedTime]="elapsedTime"
                             [counting]="buildingConsent.consentStatus === BUILDING_CONSENT_STATUS_ENUM.InProgress">
            </cb-elapsed-time>
            <cb-user-date-time-new *ngIf="buildingConsent.createdDate && buildingConsent.createdByName"
                                   label="Created"
                                   [date]="buildingConsent.createdDate"
                                   [userFullName]="buildingConsent.createdByName"
                                   [floatingLabel]="true"></cb-user-date-time-new>
            <cb-user-date-time-new *ngIf="buildingConsent.updatedDate && buildingConsent.updatedByName"
                                   label="Updated"
                                   [date]="buildingConsent.updatedDate"
                                   [userFullName]="buildingConsent.updatedByName"
                                   [floatingLabel]="true"></cb-user-date-time-new>
        </mat-card-content>
    </mat-card>
    <mat-card class="main-tabs-card flex-row flex">
        <mat-tab-group class="card-tab-group"
                       mat-stretch-tabs="false"
                       [(selectedIndex)]="selectedTabIndex">
            <mat-tab label="Details">
                <cb-consent-details *cbWaitFor="buildingConsent"
                                    [mappedItem]="buildingConsent"
                                    [numberOfRfis]="councilRfiCount"></cb-consent-details>
            </mat-tab>
            <mat-tab label="Documents ({{documentCount}})">
                <cb-lot-consent-documents [mappedItem]="buildingConsent"
                                          [systemArea]="lotConsentViewService?.systemArea$$ | async"
                                          [edit]="!buildingConsent?.isComplete"
                                          (documentCount)="documentCount = $event"
                                          (hasBuildingConsentDocument)="hasBuildingConsentDocument = $event"
                                          (reloadLotMappedItem)="reloadLotMappedItem.emit()">
                </cb-lot-consent-documents>
            </mat-tab>
            <mat-tab label="RFIs ({{councilRfiCount}})">
                <cb-lot-consent-rfis [mappedItem]="buildingConsent"
                                     [lotDto]="lotDto"
                                     (councilRfiCount)="councilRfiCount = $event"></cb-lot-consent-rfis>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>
