import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule, CbSelectListModule } from 'cb-hub-lib';
import { MatSortModule } from '@angular/material/sort';
import { CbSortableHeaderCellModule } from '@app/shared/components/sortable-header-cell/sortable-header-cell.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { MatTabsModule } from '@angular/material/tabs';
import { CbHistoryModule } from '@app/shared/components/history/history.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { DragulaModule } from 'ng2-dragula';
import { BuildProgrammeTemplatesComponent } from './build-programme-templates.component';
import { BuildProgrammeTemplateTableComponent } from './build-programme-template-table/build-programme-template-table.component';
import { BuildProgrammeTemplateDialogComponent } from './build-programme-template-dialog/build-programme-template-dialog.component';
import { BuildProgrammeTemplateViewComponent } from './build-programme-template-view/build-programme-template-view.component';
import { BuildProgrammeTemplateSidePanelComponent } from './build-programme-template-side-panel/build-programme-template-side-panel.component';
import { BuildProgrammeTemplateActivityDialogComponent } from './build-programme-template-activity-dialog/build-programme-template-activity-dialog.component';
import { BuildProgrammeTemplateActivityPredecessorDialogComponent }
    from './build-programme-template-activity-predecessor-dialog/build-programme-template-activity-predecessor-dialog.component';

@NgModule({
    declarations: [
        BuildProgrammeTemplatesComponent,
        BuildProgrammeTemplateTableComponent,
        BuildProgrammeTemplateDialogComponent,
        BuildProgrammeTemplateViewComponent,
        BuildProgrammeTemplateSidePanelComponent,
        BuildProgrammeTemplateActivityDialogComponent,
        BuildProgrammeTemplateActivityPredecessorDialogComponent,
    ],
    imports: [
        CommonModule,
        CbHubTableModule,
        CbTableModule,
        MatSortModule,
        CbSortableHeaderCellModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        CbFormsModule,
        CbSelectListModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        MatTableModule,
        CbDialogModule,
        MatCardModule,
        CbDatePipeModule,
        MatRadioModule,
        MatTooltipModule,
        CbUserDateTimeModule,
        MatTabsModule,
        CbHistoryModule,
        MatExpansionModule,
        CbEnumToLabelModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatInputModule,
        DragulaModule,
        RouterModule.forRoot([
            {
                component: BuildProgrammeTemplatesComponent,
                path: 'build-programme-templates',
                data: { breadcrumb: 'Build Programme Templates' },
            },
            {
                component: BuildProgrammeTemplateViewComponent,
                path: 'build-programme-templates/view/:id/:tabName',
                data: {
                    breadcrumb: 'View Build Programme Template',
                    breadcrumbPredecessors: [
                        { label: 'Build Programme Templates', url: '/build-programme-templates' }
                    ]
                },
            }
        ]),
    ]
})

export class BuildProgrammeTemplatesModule { }
