import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { LotQuotesListComponent } from './lot-quotes-list/lot-quotes-list.component';
import { LotQuoteCardComponent } from './lot-quote-card/lot-quote-card.component';
import { CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule, CbSelectListModule, CbInfoPopupModule } from 'cb-hub-lib';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbTruncateModule } from '@app/shared/pipe/truncate/truncate.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { CbEllipsisModule } from '@app/shared/directives/ellipsis/ellipsis.module';
import { MatMenuModule } from '@angular/material/menu';
import { LotQuoteCreateDialogComponent } from './lot-quote-create-dialog/lot-quote-create-dialog.component';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { MatRadioModule } from '@angular/material/radio';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { LotQuoteVariationOrdersDialogComponent } from './lot-quote-variation-orders-dialog/lot-quote-variation-orders-dialog.component';
import { MatListModule } from '@angular/material/list';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { LotQuoteDialogComponent } from './lot-quote-dialog/lot-quote-dialog.component';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbPrettyBoolModule } from '@app/shared/pipe/pretty-bool/pretty-bool.module';
import { LotQuoteLineDialogComponent } from './lot-quote-line-dialog/lot-quote-line-dialog.component';
import { LotQuoteSubmitDialogComponent } from './lot-quote-submit-dialog/lot-quote-submit-dialog.component';
import { CbDasherModule } from 'projects/cb-hub-lib/src/lib/pipe/dasher/dasher.module';
import {
    ClipboardModule
} from '@angular/cdk/clipboard';
import { CbProductInputFindModule } from '@app/shared/components/forms/individual/product-input-find/product-input-find.module';
import { CbSortByImpureModule } from '@app/shared/pipe/sort-by-impure/sort-by-impure.module';


@NgModule({
    exports: [
        LotQuoteCreateDialogComponent,
        LotQuoteVariationOrdersDialogComponent,
        LotQuoteDialogComponent,
        LotQuoteLineDialogComponent,
        LotQuoteSubmitDialogComponent,
        LotQuotesListComponent
    ],

    declarations: [
        LotQuotesListComponent,
        LotQuoteCardComponent,
        LotQuoteCreateDialogComponent,
        LotQuoteVariationOrdersDialogComponent,
        LotQuoteDialogComponent,
        LotQuoteLineDialogComponent,
        LotQuoteSubmitDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        CbFormsModule,
        CbLoadingSpinnerModule,
        MatCardModule,
        CbInfoMessageModule,
        CbWaitForModule,
        InfiniteScrollModule,
        MatIconModule,
        MatTooltipModule,
        CbDisplayValueModule,
        CbCurrencyModule,
        CbTruncateModule,
        MatDividerModule,
        MatButtonModule,
        CbEllipsisModule,
        CbSelectListModule,
        MatMenuModule,
        CbDialogModule,
        CbClickModule,
        MatRadioModule,
        CbDocumentsModule,
        MatListModule,
        CbEnumToLabelModule,
        CbTableModule,
        CbUserDateTimeModule,
        CbPrettyBoolModule,
        CbDasherModule,
        CbEllipsisModule,
        ClipboardModule,
        CbProductInputFindModule,
        CbSortByImpureModule,
        CbInfoPopupModule
    ]
})

export class LotQuotesModule { }
