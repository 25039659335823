<mat-card class="tab-container flex">
    <mat-tab-group class="flex"
                   mat-stretch-tabs="false"
                   (selectedTabChange)="onTabChanged($event);"
                   [selectedIndex]="selectedIndex">
        <mat-tab label="Details">
            <cb-working-drawing-details class="flex"
                                        *cbWaitFor="mappedItem"
                                        [mappedItem]="mappedItem"
                                        [lotMappedItem]="lotMappedItem"></cb-working-drawing-details>
        </mat-tab>
        <mat-tab label="Items & Changes ({{mappedItem.items?.length || 0}})">
            <cb-working-drawing-items *cbWaitFor="mappedItem"
                                      [mappedItem]="mappedItem"
                                      [showHeaderAndAddButton]="false"
                                      [showDeleteButton]="false"
                                      [addAndSave]="true">
            </cb-working-drawing-items>
        </mat-tab>
        <mat-tab label="Files ({{documentCount}})">
            <cb-document *cbWaitFor="mappedItem"
                         (documentCountChange)="documentCount = $event"
                         [entity]="mappedItem"
                         [permissions]="lotDesignPermissions?.getWorkingDrawingDocumentPermissions()"
                         [includeLinked]="true"
                         [systemArea]="SYSTEM_AREA"
                         [enableCheckbox]="true"
                         [noCard]="true"></cb-document>
        </mat-tab>
        <mat-tab label="Questions ({{questionsCount}})">
            <cb-generic-question-list *cbWaitFor="mappedItem"
                                      [entityId]="mappedItem.id"
                                      [systemArea]="SYSTEM_AREA"
                                      [lotId]="mappedItem.lotId"
                                      [edit]="true"
                                      (questionCountChange)="questionsCount = $event">
            </cb-generic-question-list>
        </mat-tab>
    </mat-tab-group>
</mat-card>
