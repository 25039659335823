<cb-dialog [dialogHeading]="(mappedItem.id > 0 ? 'Edit' : 'Create') + ' Manual Purchase Order'">
    <form #editManualOrderForm="ngForm">
        <div class="cb-margin flex-row">
            <div class="flex-col flex">
                <div class="start-center flex-row">
                    <cb-business-account-autocomplete-scroller class="cb-margin-right flex"
                                                               label="Supplier"
                                                               name="supplierSearch"
                                                               [disabled] = "mappedItem.id > 0"
                                                               [(ngModel)]="selectedSupplier"
                                                               [required]="!mappedItem.supplierName || mappedItem.supplierName.length < 1"
                                                               (optionSelected)="supplierChanged($event)"
                                                               [status]="businessAccountSearchParams.s"
                                                               [requireMatch]="true"
                                                               [matchProps]="['id']">>
                    </cb-business-account-autocomplete-scroller>
                    <button mat-icon-button
                            *ngIf="hasSupplier"
                            [disabled] = "mappedItem.id > 0"
                            (click)="clearSupplier()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
                <cb-input input="text"
                          label="Order To"
                          name="supplierName"
                          [(ngModel)]="mappedItem.supplierName"
                          [disabled]="hasSupplier"
                          [required]="true">
                </cb-input>
                <cb-input input="text"
                          label="Street Address"
                          name="supplierStreetAddress"
                          [(ngModel)]="mappedItem.supplierStreetAddress"
                          [disabled]="hasSupplier"
                          [required]="true">
                </cb-input>
                <cb-input input="text"
                          label="Suburb"
                          name="supplierSuburb"
                          [(ngModel)]="mappedItem.supplierSuburb"
                          [disabled]="hasSupplier"
                          [required]="true">
                </cb-input>
                <cb-input input="text"
                          label="City"
                          name="supplierCity"
                          [(ngModel)]="mappedItem.supplierCity"
                          [disabled]="hasSupplier"
                          [required]="true">
                </cb-input>
                <cb-input input="text"
                          label="Post Code"
                          name="postCode"
                          [(ngModel)]="mappedItem.supplierPostCode"
                          [disabled]="hasSupplier">
                </cb-input>
                <cb-select label="Address Region"
                           name="addressRegions"
                           [(ngModel)]="mappedItem.supplierAddressRegionId"
                           [options]="addressRegions"
                           (change)="addressRegionSelected()"
                           [required]="true"
                           [disabled]="hasSupplier">
                </cb-select>
                <cb-input type="email"
                          *ngIf="!hasSupplier"
                          label="Email Address"
                          name="emailAddressText"
                          [(ngModel)]="mappedItem.supplierEmailAddress">
                </cb-input>
                <cb-select *ngIf="hasSupplier"
                           label="Email Address"
                           name="emailAddress"
                           [(ngModel)]="mappedItem.supplierEmailAddress"
                           [options]="supplierContacts"
                           labelProp="name"
                           valueProp="email">
                </cb-select>
                <cb-select label="Cost Nature"
                           name="costNature"
                           [(ngModel)]="mappedItem.costNature"
                           [options]="costNatures"
                           [disabled]="isTransformedSsr"
                           [required]="true">
                </cb-select>
                <div class="flex-col"
                     style="display: grid !important"
                     *ngIf="isOrderExtraToSupplier">
                    <div class="start-center flex-row">
                        <cb-business-account-autocomplete-scroller class="cb-margin-right flex"
                                                                   label="Chargeable Business Account"
                                                                   name="businessAccountSearch"
                                                                   [(ngModel)]="selectedChargeableBusinessAccount"
                                                                   (optionSelected)="chargeableBusinessAccountChanged()"
                                                                   [status]="businessAccountSearchParams.s"
                                                                   [requireMatch]="true"
                                                                   [required]="true"
                                                                   [matchProps]="['id']">
                        </cb-business-account-autocomplete-scroller>
                        <button mat-icon-button
                                *ngIf="hasChargeableBusinessAccount"
                                (click)="clearselectedChargeableBusinessAccount()">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </div>
                    <mat-form-field>
                        <mat-label>Chargable Business Account Contact</mat-label>
                        <input type="text"
                               matInput
                               [(ngModel)]="selectedChargeableContact"
                               name="chargeableContactAuto"
                               (keyup)="chargeableContactTextChanged($event.target.value)"
                               [matAutocomplete]="chargeableContactAuto"
                               required="true"/>
                        <mat-autocomplete #chargeableContactAuto="matAutocomplete"
                                          [displayWith]="displayContactWith"
                                          (optionSelected)="chargeableContactChanged($event.option.value)">
                            <mat-option *ngFor="let option of chargeableBusinessAccountContacts"
                                        [value]="option">{{option.name}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <cb-text-area label="Discussion Comments"
                                  name="discussionComments"
                                  [(ngModel)]="mappedItem.discussionDescription"
                                  [maxlength]="1024"></cb-text-area>
                    <cb-datepicker name="discussionDate"
                                   [required]="true"
                                   [(ngModel)]="mappedItem.discussionDate"
                                   label="Discussion Date"
                                   pickerType="calendar"></cb-datepicker>
                </div>
                <cb-select label="Reason"
                           name="manualOrderReason"
                           [(ngModel)]="mappedItem.reasonId"
                           [options]="manualOrderReasonList"
                           [required]="true">
                </cb-select>
                <cb-text-area label="Reason Description"
                              name="reasonDescription"
                              [(ngModel)]="mappedItem.reasonDescription">
                </cb-text-area>
                <cb-select label="Build Stage"
                           name="buildStage"
                           [(ngModel)]="mappedItem.buildStageId"
                           [options]="buildStages"
                           [required]="true"
                           [disabled]="mappedItem.hasBuildProgrammeActivity"></cb-select>
                <cb-build-activity-autocomplete-scroller label="Build Activity"
                                                         name="buildActivitySearch"
                                                         [(ngModel)]="selectedBuildActivity"
                                                         (optionSelected)="selectedBuildActivityChanged($event)"
                                                         (searchTextChanged)="clearBuildActivity()"
                                                         [requireMatch]="true"
                                                         [matchProps]="['id']"
                                                         [required]="true"
                                                         [disabled]="mappedItem.hasBuildProgrammeActivity">
                </cb-build-activity-autocomplete-scroller>

                <!-- Only allow override of compliance if the activity needs compliance AND this is a new manual order -->
                <div *ngIf="selectedBuildActivity &&
                            selectedBuildActivity.hasCompliance &&
                            (mappedItem?.id ?? 0 <= 0)">
                    <cb-checkbox name="RequiresCompliance"
                                 label="Compliance documents are not required"
                                 [(ngModel)]="mappedItem.forceNoCompliance"></cb-checkbox>
                </div>
                <cb-date-duration-calculator name="activityStartDate"
                                             [(startDate)]="mappedItem.orderDate"
                                             [duration]="mappedItem.activityDuration"
                                             (durationChange)="durationHasChanged($event)"
                                             [lotId]="lotDto.id"
                                             [required]="true"
                                             pickerType="calendar"
                                             [disabled]="mappedItem.hasBuildProgrammeActivity">
                </cb-date-duration-calculator>
                <cb-text-area label="Comments"
                              name="comments"
                              [(ngModel)]="mappedItem.comments"
                              [maxlength]="1024"></cb-text-area>
            </div>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="!mappedItem.buildActivityId ||
                    saveInProgress ||
                    editManualOrderForm.invalid ||
                    editManualOrderForm.pristine ||
                    !isDurationValid()">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
