import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbInfoMessageModule, CbInfoPopupModule, CbLoadingSpinnerModule, CbTableModule} from 'cb-hub-lib';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {CbEnumToLabelModule} from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import {DocumentGroupsComponent} from './document-groups.component';
import {DocumentGroupDialogComponent} from './document-group-dialog/document-group-dialog.component';
import {DocumentGroupTypeDialogComponent} from './document-group-type-dialog/document-group-type-dialog.component';

@NgModule({
    declarations: [
        DocumentGroupsComponent,
        DocumentGroupDialogComponent,
        DocumentGroupTypeDialogComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbEnumToLabelModule,
        CbInfoPopupModule,
        CbTableModule,
    ],
    exports: [
        DocumentGroupsComponent
    ]
})
export class DocumentGroupsModule { }
