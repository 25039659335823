import { NgModule } from '@angular/core';
import { PurchaseOrdersComponent } from './pages/purchase-orders/purchase-orders.component';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CbLoadingSpinnerModule, CbSelectListModule } from 'cb-hub-lib';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        PurchaseOrdersComponent
    ],
    imports: [
        RouterModule.forRoot([
            {
                component: PurchaseOrdersComponent,
                // angular 7+ root routes must start with 'ng'
                path: 'purchase-orders',
                data: {
                    breadcrumb: 'purchase orders'
                }
            }
        ], {}),
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatSelectModule,
        MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatCardModule,
        MatCheckboxModule,
        DragulaModule.forRoot(),
        InfiniteScrollModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbSelectListModule
    ]
})
export class PurchaseOrdersModule { }
