<div class="cb-margin-2x flex-row">
    <div class="flex-col flex-50">
        <cb-display-value-new label="Council Name"
                              [value]="councilRfi?.councilName"></cb-display-value-new>
        <cb-display-value-new label="Received From"
                              [value]="councilRfi?.receivedFrom"></cb-display-value-new>
        <cb-display-value-new label="External Reference"
                              [value]="councilRfi?.externalReference"></cb-display-value-new>
        <cb-display-value-new label="RFI Date"
                              [value]="councilRfi?.rfiDate | date"></cb-display-value-new>
        <cb-display-value-new label="RFI Due Date"
                              [value]="councilRfi?.rfiDueDate | date"></cb-display-value-new>
        <cb-selection-list name="relatesTo"
                           [(ngModel)]="rFIAspects.value"
                           [ngModelOptions]="{standalone: true}"
                           [options]="RELATES_TO"
                           [readonly]="true"
                           [slim]="true"
                           label="Relates To"
                           checkboxPosition="before">
        </cb-selection-list>
    </div>
    <div class="flex-col flex-50">
        <cb-display-value-new label="Related Consents">
            <ul *ngIf="councilRfi?.relatedConsents?.length > 0"
                class="list">
                <li *ngFor="let consent of councilRfi?.relatedConsents">
                    {{consent.label}}
                    <button mat-icon-button
                            (click)="viewLotConsent($event, consent)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </li>
            </ul>
            <span *ngIf="councilRfi.relatedConsents?.length < 1">-</span>
        </cb-display-value-new>
        <cb-text-area label="Notes"
                      name="notes"
                      [(ngModel)]="councilRfi.notes"
                      [readonly]="true"
                      [ngModelOptions]="{standalone: true}">

        </cb-text-area>
    </div>
</div>
