<cb-dialog>
    <form class="flex-col flex"
          #buildMilestoneTemplateForm="ngForm">
        <cb-input type="text"
                  name="code"
                  label="Code"
                  [required]="true"
                  [(ngModel)]="mappedItem.code">
        </cb-input>

        <cb-input type="text"
                  name="name"
                  label="Milestone Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.name">
        </cb-input>

        <cb-text-area label="Formula"
                      name="formula"
                      [(ngModel)]="mappedItem.formula"
                      [required]="true"></cb-text-area>

        <cb-checkbox name="isActive"
                     label="Is Active"
                     [(ngModel)]="mappedItem.isActive">
        </cb-checkbox>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!buildMilestoneTemplateForm.dirty || !buildMilestoneTemplateForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>