<h3 class="mat-title">
    Integration Logs
</h3>
<mat-tab-group mat-stretch-tabs="false">
    <mat-tab label="ABM Exception Logs">
        <ng-template matTabContent>
            <cb-exception-logs class="cb-margin-top flex-col"></cb-exception-logs>
        </ng-template>
    </mat-tab>
    <mat-tab label="ABM Message History">
        <ng-template matTabContent>
            <cb-message-history class="cb-margin-top flex-col"></cb-message-history>
        </ng-template>
    </mat-tab>
    <mat-tab label="ABM Inbound Errors">
        <ng-template matTabContent>
            <cb-abm-logs class="cb-margin-top flex-col"></cb-abm-logs>
        </ng-template>
    </mat-tab>
    <mat-tab label="Contact Integration Exception Logs">
        <ng-template matTabContent>
            <cb-contact-integration-exception-logs class="cb-margin-top flex-col"></cb-contact-integration-exception-logs>
        </ng-template>
    </mat-tab>
</mat-tab-group>
