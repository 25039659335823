<cb-dialog>
    <form class="flex-col flex"
          #scheduleItemForm="ngForm">
        <ng-container *ngIf="hasParent">
            <cb-display-value-new label="Parent"
                                  [value]="parentDisplayText">
            </cb-display-value-new>
        </ng-container>
        <cb-input type="text"
                  name="name"
                  label="Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.name">
        </cb-input>
        <cb-text-area name="description"
                      label="Description"
                      [(ngModel)]="mappedItem.description"
                      [maxlength]="1000">
        </cb-text-area>
        <div style="display: block;"
             class="cb-margin-button flex-row flex"
             *ngIf="mappedItem?.parentSlotId || mappedItem?.parent || mappedItem?.$parentSlot">
            <cb-checkbox label="Required Child"
                         name="requireChild"
                         id="requiredChild"
                         [(ngModel)]="mappedItem.isRequired">
            </cb-checkbox>
            <cb-info-popup id="requiredChildInfo"
                           tooltip="Should this Item always be included with the Parent on a Spec Template?">
            </cb-info-popup>
        </div>
        <cb-select label="House Area"
                   name="houseArea"
                   [(ngModel)]="mappedItem.houseArea"
                   [options]="houseAreas"
                   [required]="true"
                   [disabled]="houseAreaDisabled"
                   [valueProp]="WHOLE_OPTION_VALUE_PROP">
        </cb-select>
        <cb-select label="Cost Type"
                   name="costType"
                   [(ngModel)]="mappedItem.costType"
                   [options]="costTypes"
                   [required]="true"
                   (change)="onCostTypeChange()">
        </cb-select>
        <cb-product-category-autocomplete-scroller class="cb-margin-top"
                                                   label="Product Category Path"
                                                   name="categories"
                                                   [required]="mappedItem.costType === COST_TYPE_ENUM.Actual"
                                                   [(ngModel)]="selectedProductCategoryModel"
                                                   [requireMatch]="true"
                                                   [matchProps]="['id']"
                                                   (optionSelected)="productCategorySelected($event, scheduleItemForm.form)">
        </cb-product-category-autocomplete-scroller>
        <div class="flex-row">
            <cb-selection-list class="slot-tags-select"
                               label="Tags"
                               name="slotTags"
                               [(ngModel)]="mappedItem.tags"
                               [options]="slotTags"
                               [strikeThroughInactive]="true"
                               [valueProp]="WHOLE_OPTION_VALUE_PROP"
                               checkboxPosition="before">
            </cb-selection-list>
        </div>
        <ng-container *ngIf="!mappedItem?.parentSlotId && !mappedItem?.parent && !mappedItem?.$parentSlot">
            <div class="flex-row">
                <h4 class="mat-h4 cb-margin-bottom-nil cb-margin-top-2x">
                    <b>
                        Child Schedule Items
                    </b>
                </h4>
                <span class="flex"></span>
                <button mat-raised-button
                        class="save-button"
                        color="primary"
                        (cbClick)="createChild(scheduleItemForm?.form)"
                        [disabled]="!mappedItem?.name || !mappedItem?.houseArea || !mappedItem?.costType">
                    Create Child
                </button>
            </div>
            <small>
                <i>
                    Child Schedule Items can only be from the same House Area as the parent Schedule Item
                </i>
            </small>
            <cb-collection-builder [collection]="fullChildSlots"
                                   [reorderingEnabled]="true"
                                   dragulaId="childSlots"
                                   (sortOrderChanged)="sortOrderChange($event, scheduleItemForm.form)"
                                   sortOrderValueProp="reportOrder"
                                   [displayedColumns]="childSlotColumns"
                                   [newCollectionItem]="newChildSlotCollectionItem"
                                   [addButtonDisabled]="childSlotAddButtonDisabled()"
                                   (collectionItemRemoved)="childSlotCollectionItemRemoved($event, scheduleItemForm.form)"
                                   (collectionItemAdded)="childSlotCollectionItemAdded($event, scheduleItemForm.form)">
                <ng-container addCollectionItemRow>
                    <td>
                        <cb-slot-autocomplete-scroller class="cb-margin-top"
                                                       label="Child Schedule Item"
                                                       name="newChildSlot"
                                                       style="min-width: 300px;"
                                                       [(ngModel)]="newChildSlotCollectionItem"
                                                       [requireMatch]="true"
                                                       [matchProps]="['id']"
                                                       [specGroupId]="mappedItem?.houseArea?.id"
                                                       [excludeChildren]="true"
                                                       [excludeParents]="true"
                                                       [disabled]="!mappedItem?.name || !mappedItem?.houseArea || !mappedItem?.costType"
                                                       [excludeIds]="mappedItem.id > 0 ? [mappedItem.id] : null"
                                                       [resultsToIncl]="removedChildSlots"
                                                       [resultsToExcl]="addedChildSlots"
                                                       (optionSelected)="slotSelected($event)">
                        </cb-slot-autocomplete-scroller>
                    </td>
                    <td colspan="4"></td>
                </ng-container>
                <ng-template #collectionItemColumns
                             let-childSlotCollectionItem>
                    <cb-td-text [value]="childSlotCollectionItem.name"></cb-td-text>
                    <cb-td-enum [value]="childSlotCollectionItem.costType"
                                [enum]="COST_TYPE_ENUM">
                    </cb-td-enum>
                    <cb-td-list [list]="childSlotCollectionItem.tags">
                    </cb-td-list>
                    <cb-td-checkbox [value]="childSlotCollectionItem.isActive"></cb-td-checkbox>
                    <td>
                        <cb-checkbox name="RequiredChildCheckbox_{{childSlotCollectionItem.name}}_{{childSlotCollectionItem.reportOrder}}"
                                     [slim]="true"
                                     [(ngModel)]="childSlotCollectionItem.isRequired">
                        </cb-checkbox>
                    </td>
                </ng-template>
            </cb-collection-builder>
        </ng-container>
        <h4 class="mat-h4 cb-margin-bottom-nil cb-margin-top">
            <b>
                External References
            </b>
        </h4>
        <cb-collection-builder [collection]="mappedItem.externalReferences"
                               [displayedColumns]="externalReferenceColumns"
                               [newCollectionItem]="newExternalReferenceCollectionItem"
                               [addButtonDisabled]="externalReferencesAddButtonDisabled()"
                               (collectionItemAdded)="externalReferenceCollectionItemAdded($event)"
                               (collectionItemRemoved)="externalReferenceCollectionItemRemoved($event, scheduleItemForm.form)">
            <ng-container addCollectionItemRow>
                <td>
                    <cb-input type="text"
                              name="newItemExternalId"
                              label="new Item External Id"
                              [(ngModel)]="newExternalReferenceCollectionItem.externalReferenceId">
                    </cb-input>
                </td>
                <td>
                    <cb-select *ngIf="showExternalSystemSelect"
                               name="newItemExternalSystem"
                               [(ngModel)]="newExternalReferenceCollectionItem.externalSystem"
                               [options]="externalSystemList">
                    </cb-select>
                </td>
            </ng-container>
            <ng-template #collectionItemColumns
                         let-externalReferenceCollectionItem>
                <cb-td-text [value]="externalReferenceCollectionItem.externalReferenceId">
                </cb-td-text>
                <cb-td-enum [value]="externalReferenceCollectionItem.externalSystem"
                            [enum]="EXTERNAL_SYSTEM_ENUM">
                </cb-td-enum>
            </ng-template>
        </cb-collection-builder>
        <footer class="cb-margin-top-2x start-center flex-row">
            <cb-checkbox label="Is Active"
                         name="isActive"
                         [(ngModel)]="mappedItem.isActive">
            </cb-checkbox>
            <span class="flex"></span>
            <button mat-raised-button
                    class="save-button"
                    color="primary"
                    (cbClick)="save()"
                    [clickLimit]="1"
                    *ngIf="!mappedItem?.$parentSlot"
                    [disabled]="scheduleItemForm.pristine || scheduleItemForm.invalid">
                Save
            </button>
            <button mat-raised-button
                    class="save-button"
                    color="primary"
                    (cbClick)="addToParent()"
                    [clickLimit]="1"
                    *ngIf="mappedItem?.$parentSlot"
                    [disabled]="scheduleItemForm.pristine || scheduleItemForm.invalid">
                Add To Parent
            </button>
            <button mat-raised-button
                    (click)="cancel()">
                Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
