import { Component, Input, OnChanges } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { UpdateContractConditionsDto, UpdateDateDto, WipDocument, WipFavoriteView, WipSearchDto, WipService } from '@app/logic/wip/wip.service';
import { CbTruncateModule } from '@app/shared/pipe/truncate/truncate.module';
import { finalize } from 'rxjs/operators';
import { environment } from '@src/environments/environment';
import { WipColumn, WipConstants } from '@app/views/wip/helpers/wipConstants';
import { CbSelectListModule } from 'cb-hub-lib';
import { IUserDto } from '@app/logic/users';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ILocationDto, LocationLogicService } from '@app/logic/location';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ProjectsLogicService } from '@app/logic/projects';
import { IIdAndLabelDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-wip-table',
    templateUrl: './wip-table.component.html',
    styleUrls: ['./wip-table.component.scss'],
    standalone: true,
    imports: [
        MatTableModule,
        CommonModule,
        CbTruncateModule,
        CbSelectListModule,
        MatIconModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatToolbarModule,
        CbFormsModule,
        MatDatepickerModule,
        FormsModule,
        MatInputModule
    ]
})
export class WipTableComponent implements OnChanges {
    @Input() public selectedView: WipFavoriteView;
    @Input() public buildingConsultants: IUserDto[];
    @Input() public businessEntitiesDict: { [id: number]: string };
    @Input() public projects:  { [id: string]: number };
    @Input() public contractTypes: SelectListItem[];
    @Input() public districts: ILocationDto[];
    // It's important to add any input to the areAllInputsAvailable check; otherwise, you might send an extra request to the backend and receive duplicate results
    public areas: ILocationDto[];
    public releaseStages: IIdAndLabelDto[];

    public filter = {
        projectNames: new FormControl<string[]>([]),
        businessEntities:new FormControl<number[]>([]),
        contractTypes:new FormControl<number[]>([]),
        buildingConsultants: new FormControl<string[]>([]),
        districts: new FormControl<number[]>([]),
        areas: new FormControl<number[]>([]),
        releaseStages: new FormControl<IIdAndLabelDto[]>([]),
    };

    public sortBy = '';
    public sortDirection: 'asc' | 'desc' = 'desc';

    public displayedColumns: WipColumn[] = WipConstants.displayedColumns;
    public stickyColumn: Set<string> = new Set();
    public maxLength = 20;
    public currentPage = 1;
    public dataSource: WipDocument[] = [];
    private hasMoreDocuments = true;
    private isLoading = false;
    private readonly pageSize = 100;
    private readonly preLoadDistance = 400;

    constructor(private wipService: WipService,
                private readonly locationLogicService: LocationLogicService,
                private readonly projectsLogicService: ProjectsLogicService) {
    }

    private reloadTable(): void {
        this.displayedColumns = this.selectedView.columns
            .map((colKey) => WipConstants.displayedColumnsMap[colKey])
            .filter((col): col is WipColumn => !!col);
        this.stickyColumn = new Set();
        this.selectedView.stickyColumns.forEach((columnName) => {
            this.stickyColumn.add(columnName);
        });
        this.dataSource = [];
        this.currentPage = 1;
        this.hasMoreDocuments = true;
        this.isLoading = false;
        this.fetchWipDocuments();
    }

    public ngOnChanges(): void {
        if (this.areAllInputsAvailable()) {
            this.clearAllSets();
            this.clearAllFilters();
            this.reloadTable();
        }
    }

    public get columnKeys(): string[] {
        return this.displayedColumns.map(col => col.key);
    }

    public onScroll(event: Event): void {
        const target = event.target as HTMLElement;
        const position = target.scrollTop + target.clientHeight;
        const maxScroll = target.scrollHeight;

        if (position >= maxScroll - this.preLoadDistance && !this.isLoading && this.hasMoreDocuments) {
            this.fetchWipDocuments();
        }
    }

    private areAllInputsAvailable(): boolean {
        return this.selectedView != null &&
            this.buildingConsultants != null &&
            this.businessEntitiesDict != null &&
            this.districts != null &&
            this.projects != null &&
            this.contractTypes != null;
    }

    public changeSortOrder(columnKey: string): void {
        if (this.sortBy === columnKey) {
            this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
        } else {
            this.sortBy = columnKey;
            this.sortDirection = 'desc';
        }
        this.reloadTable();
    }

    public isSorted(columnKey: string): boolean {
        return this.sortBy === columnKey;
    }

    public getSortIcon(columnKey: string): string {
        if (!this.isSorted(columnKey)) {
            return 'arrow_upward';
        }
        return this.sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward';
    }

    private fetchWipDocuments(): void {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;
        const searchDto: WipSearchDto = {
            currentPage: this.currentPage,
            viewId: this.selectedView.id,
            buildingConsultants: this.filter.buildingConsultants.value,
            businessEntities: this.filter.businessEntities.value,
            projectNames:  this.filter.projectNames.value,
            contractTypes: this.filter.contractTypes.value,
            releaseStages: this.filter.releaseStages.value.map(x=>x.id),
            areas: this.filter.areas.value,
            districts: this.filter.districts.value,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        };

        this.wipService.loadMoreWipDocuments(searchDto).pipe(
            finalize(() => this.isLoading = false)
        ).subscribe({
            next: (documents) => {
                this.dataSource = [...this.dataSource, ...documents];
                this.hasMoreDocuments = documents.length === this.pageSize;
                if (this.hasMoreDocuments) {
                    this.currentPage++;
                }
                this.updateFilterSets(documents);

            },
            error: (error) => {
                console.error('Failed to fetch WIP documents', error);
            }
        });
    }

    // all possible filters
    private buildingConsultantsSet: Set<string> = new Set();
    private projectsSet: Set<string> = new Set();
    private businessEntitiesSet: Set<string> = new Set();
    private contractTypesSet: Set<number> = new Set();
    private districtSet: Set<string> = new Set();
    private releaseStagesSet: Set<number> = new Set();


    public clearAllSets(): void {
        this.buildingConsultantsSet = new Set();
        this.projectsSet = new Set();
        this.businessEntitiesSet = new Set();
        this.contractTypesSet = new Set();
        this.districtSet = new Set();
        this.releaseStagesSet = new Set();
    }
    private updateFilterSets(documents: WipDocument[]): void {
        documents.forEach((doc) => {
            if (doc.buildingConsultant) {
                this.buildingConsultantsSet.add(doc.buildingConsultant);
            }
            if (doc.projectName) {
                this.projectsSet.add(doc.projectName);
            }
            if (doc.businessEntityId) {
                this.businessEntitiesSet.add(doc.businessEntityId.toString());
            }
            if (doc.contractTypeId) {
                this.contractTypesSet.add(doc.contractTypeId);
            }
            if (doc.district) {
                this.districtSet.add(doc.district);
            }
            if (doc.releaseStage) {
                this.releaseStagesSet.add(doc.releaseStageId);
            }
        });
    }

    public getDistricts(): ILocationDto[] {
        return this.districts ? this.districts.filter(x => this.districtSet.has(x.district.label)) : [];
    }

    public getContractTypes(): SelectListItem[] {
        return this.contractTypes ? this.contractTypes.filter(x => this.contractTypesSet.has(x.id)) : [];
    }

    public getBuildingConsultants(): IUserDto[] {
        return this.buildingConsultants ? this.buildingConsultants.filter(x => this.buildingConsultantsSet.has(x.label)) : [];
    }

    public getProject(): string[] {
        if (this.projects) {
            return Object.keys(this.projects).filter(x => this.projectsSet.has(x));
        }
        return [];
    }

    public getReleases(): IIdAndLabelDto[] {
        return this.releaseStages ? this.releaseStages.filter(x => this.releaseStagesSet.has(x.id)) : [];
    }

    public resetAllFilters(): void {
        this.clearAllFilters();
        this.reloadTable();
    }

    private clearAllFilters(): void {
        this.filter.projectNames.setValue([]);
        this.filter.businessEntities.setValue([]);
        this.filter.contractTypes.setValue([]);
        this.filter.buildingConsultants.setValue([]);
        this.filter.areas.setValue([]);
        this.areas = [];
        this.filter.districts.setValue([]);
        this.filter.releaseStages.setValue([]);
        this.releaseStages = [];
    }

    public onJobNumberClick(id: string): void {
        const url = `${environment.uri}/lots/${id}/summary`;
        const newTab = window.open(url, '_blank');

        if (newTab) {
            newTab.focus();
        }
    }

    public onSelectionChange(): void {
        this.reloadTable();
    }

    public getBusinessEntitiesNames(): string[] {
        if (this.businessEntitiesDict) {
            return Object.keys(this.businessEntitiesDict).filter(x => this.businessEntitiesSet.has(x));
        }
        return [];
    }

    public getBusinessEntitiesName(businessEntityId: number): string {
        if (this.businessEntitiesDict) {
            return this.businessEntitiesDict[businessEntityId];
        }
        return '';
    }

    public onProjectChange(): void {
        this.filter.releaseStages.setValue([]);
        if (this.filter.projectNames.value.length > 0) {
            const projectNames = this.filter.projectNames.value;
            if (projectNames.length > 0) {
                this.projectsLogicService.getProjectsReleaseLabels(projectNames.map(name=> this.projects[name]))
                    .subOnce(idAndLabels => {
                        this.releaseStages = idAndLabels;
                    });
            }
        }
        else {
            this.releaseStages = [];
        }
        this.reloadTable();
    }

    public onDistrictChange(): void {
        if (this.filter.districts.value.length > 0) {
            this.locationLogicService.getLocationListByIds(this.filter.districts.value).subOnce(areas => {
                this.areas = areas;
            });
        }
        else {
            this.areas = [];
            this.filter.areas.setValue([]);
        }
        this.reloadTable();
    }

    public onSettlementDateChange(element: WipDocument): void {
        const dto: UpdateDateDto = {
            lotId: element.lotId,
            date: this.getDate(element.settlementDate)
        };

        this.wipService.updateSettlementDate(dto).subscribe({
            next: () => {
                element.editingSettlementDate = false;
            },
            error: (err) => {
                console.error('Failed to update settlement date:', err);
            }
        });
    }

    public onFloorDownDateChange(element: WipDocument): void {
        const dto: UpdateDateDto = {
            lotId: element.lotId,
            date: this.getDate(element.floorDownDate)
        };

        this.wipService.updateFloorDownDate(dto).subscribe({
            next: () => {
                element.editingFloorDownDate = false;
            },
            error: (err) => {
                console.error('Failed to update floorDown date:', err);
            }
        });
    }

    public startEditingContractConditions(element: WipDocument): void {
        element.editingContractConditions = true;
    }

    public onContractConditionsChange(element: WipDocument): void {
        const dto: UpdateContractConditionsDto = {
            lotId: element.lotId,
            contractConditions: element.contractConditions
        };

        this.wipService.updateContractConditions(dto).subscribe({
            next: () => {
                // Set editing mode to false to collapse the editor
                element.editingContractConditions = false;
            },
            error: (err) => {
                console.error('Failed to update contract conditions:', err);
            }
        });
    }

    private getDate(date: string): string {
        return date
            ? new Date(date).toISOString()
            : '';
    }

    public isSticky(columnName: string): boolean {
        return this.stickyColumn.has(columnName);
    }

    public isEmpty(contractConditions: string): boolean {
        return (contractConditions || '').trim() !== '';
    }

    public onRowClick($event: MouseEvent, element: WipDocument): void {
        const target = $event.target as HTMLElement;

        if (target.closest('.editable-block')) {
            return;
        }

        element.editingContractConditions = false;
        element.editingFloorDownDate = false;
        element.editingSettlementDate = false;
    }
}
