<div class="cb-margin flex-col"
     *ngIf="searchResultsLoaded$ | async">
    <mat-card class="cb-margin-top">
        <form class="cb-margin-right-3x start-center flex-row">
            <mat-icon class="cb-margin-right-2x">search</mat-icon>
            <cb-input class="cb-margin-right"
                      name="searchText"
                      label="Search"
                      [(ngModel)]="userCacheItem.data.query">
            </cb-input>
            <cb-select-list class="cb-margin-right"
                            label="Filter Quotes Status"
                            [allOptions]="quoteStatus"
                            [multiple]="true"
                            [value]="userCacheItem?.data?.statusIds"
                            (selectChanged)="userCacheItem.data.statusIds = $event.value">
            </cb-select-list>
            <button class="cb-margin-top"
                    mat-raised-button
                    color="primary"
                    [matMenuTriggerFor]="createQuoteMenu">
                Create Quote
            </button>
            <mat-menu #createQuoteMenu="matMenu">
                <button mat-menu-item
                        (click)="createQuoteRequired()">
                    <span>Quote Required</span>
                </button>
                <button mat-menu-item
                        (click)="createQuoteReceived()">
                    <span>&nbsp;Quote Received</span>
                </button>
            </mat-menu>
            <span class="flex"></span>
            <button mat-icon-button
                    (click)="toggeleColumnWitdh()"
                    [disabled]="quotedSpecItems.length < 1">
                <mat-icon>{{showSpecificationItemsPanel ? 'arrow_forward' : 'menu'}}</mat-icon>
                <span class="counter">{{quotedSpecItems.length}}</span>
            </button>
        </form>
    </mat-card>
    <div class="flex-row">
        <div #scrollContainer
             class="cb-margin-top lot-quotes-scroller flex"
             [ngClass]="'flex-' + mainPanelWidth">
            <div class="flex-wrap"
                 infiniteScroll
                 (scrolled)="doSearch()"
                 [immediateCheck]="false"
                 [fromRoot]="true"
                 [infiniteScrollContainer]="scrollContainer">
                <cb-lot-quote-card class="quote-list-item xl-33 md-100 lg-50 sm-100 xs-100"
                                   [lotQuoteSearchItem]="item"
                                   [isLotSpecLocked]="lotMappedItem.lotSpecLocked"
                                   [lotMappedItem]="lotMappedItem"
                                   (quoteDuplicated)="quoteDuplicated($event)"
                                   (refreshSearch)="loadSearchParams()"
                                   *ngFor="let item of searchResults">
                    {{item.quoteNumber}}
                </cb-lot-quote-card>
                <cb-info-message *ngIf="searchResults?.length < 1"
                                 message="There were no Quotes found">
                </cb-info-message>
            </div>
        </div>
        <div class="flex"
             [ngClass]="'flex-' + sidePanelWidth"
             *ngIf="showSpecificationItemsPanel">
            <mat-list>
                <div mat-subheader>Quoted Specification Items</div>
                <mat-list-item *ngFor="let specItem of quotedSpecItems">
                    <div matListItemTitle cbEllipsis>{{ specItem.originalCostType | cbEnumToLabel: COST_TYPE_ENUM }} -
                        {{ specItem.productDisplay }}
                    </div>
                    <div class="flex-row currency-line"
                         matListItemLine>
                        <span>{{ specItem.rate | cbCurrency }} </span>
                        <span class="flex"></span>
                        <span>Amount Spent:&nbsp;{{ specItem.amountSpent | cbCurrency }}</span>
                    </div>
                    <mat-divider class="custom-divider" [inset]="true"></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
</div>
<cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
