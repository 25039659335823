<div class="flex-col">
    <div class="flex-row">
        <div class="flex-col flex">
            <div class="flex-row flex">
                <div class="cb-padding-right-3x flex-col flex-50">
                    <h1 class="mat-title"
                        style="margin-bottom: 23px">
                        National
                    </h1>
                    <div class="start-center flex-row">
                        <cb-checkbox name="nationalShouldHideSsrLineSubtotals"
                                     label="Hide SSR Line Quantities"
                                     class="cb-margin-bottom-2x cb-margin-right-3x cb-margin-top-2x"
                                     [(ngModel)]="buildActivity.getNationalSchedule().shouldHideSsrLineSubtotals">
                        </cb-checkbox>
                        <cb-checkbox name="nationalShouldHideSsrLineSubtotals"
                                     label="Hide SSR Line Subtotals"
                                     class="cb-margin-bottom-2x cb-margin-top-2x"
                                     [(ngModel)]="buildActivity.getNationalSchedule().shouldHideSsrLineQuantities">
                        </cb-checkbox>
                    </div>
                    <div class="cb-table flex-col flex"
                         style="overflow: visible">
                        <mat-table #nationalSpecificationsTable
                                   [attr.data-name]="'nationalSpecificationsTable'"
                                   [dataSource]="buildActivity.getNationalSchedule().buildActivityScheduleSpecificationsLineItems">
                            <ng-container matColumnDef="specifications">
                                <th mat-header-cell
                                    *matHeaderCellDef>
                                    Specifications
                                </th>
                                <td mat-cell
                                    *matCellDef="let spec; let i = index;">
                                    <div class="cb-schedule-textarea-container start-center flex-row flex">
                                        <b>
                                            {{spec.sortOrder}}
                                        </b>
                                        <textarea cbTextAreaAutoUpdateHeight
                                                  cbTrimValue
                                                  [attr.data-line-item]="'specifications' + spec.sortOrder"
                                                  class="{{'specifications' + spec.sortOrder}}"
                                                  [maxlength]="250"
                                                  name="specificationNationalText-{{i}}"
                                                  rows="1"
                                                  [(ngModel)]="spec.text"
                                                  mat-select-on-focus>
                                            {{spec.text}}
                                        </textarea>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell
                                    *matHeaderCellDef>
                                    <button mat-icon-button
                                            color="accent"
                                            (click)="addLineItem('buildActivityScheduleSpecificationsLineItems', 'nationalSpecificationsTable', 'regionalSpecificationsTable')">
                                        <mat-icon>
                                            add
                                        </mat-icon>
                                    </button>
                                </th>
                                <td mat-cell
                                    *matCellDef="let spec">
                                    <span class="counter mat-body">
                                        {{spec?.text?.length}} / 250
                                    </span>
                                </td>
                            </ng-container>
                            <tr mat-header-row
                                *matHeaderRowDef="['specifications', 'actions']">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: specTableColumns;">
                            </tr>
                        </mat-table>
                    </div>
                    <div class="cb-table flex-col flex"
                         style="overflow: visible">
                        <mat-table #nationalScopesTable
                                   [attr.data-name]="'nationalScopesTable'"
                                   [dataSource]="buildActivity.getNationalSchedule().scopeOfWorkLineItems">
                            <ng-container matColumnDef="scopes">
                                <th mat-header-cell
                                    *matHeaderCellDef>
                                    Scope of Work
                                </th>
                                <td mat-cell
                                    *matCellDef="let scope; let i = index;">
                                    <div class="cb-schedule-textarea-container start-center flex-row flex">
                                        <b>
                                            {{scope.sortOrder}}
                                        </b>
                                        <textarea [attr.data-line-item]="'scopes' + scope.sortOrder"
                                                  cbTextAreaAutoUpdateHeight
                                                  class="{{'scopes' + scope.sortOrder}}"
                                                  [maxlength]="250"
                                                  name="scopeNationalText-{{i}}"
                                                  rows="1"
                                                  cbTrimValue
                                                  [(ngModel)]="scope.text"
                                                  mat-select-on-focus>
                                        </textarea>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell
                                    *matHeaderCellDef>
                                    <button mat-icon-button
                                            color="accent"
                                            (click)="addLineItem('scopeOfWorkLineItems', 'nationalScopesTable', 'regionalScopesTable')">
                                        <mat-icon>
                                            add
                                        </mat-icon>
                                    </button>
                                </th>
                                <td mat-cell
                                    *matCellDef="let scope">
                                    <span class="counter mat-body">
                                        {{scope?.text?.length}} / 250
                                    </span>
                                </td>
                            </ng-container>
                            <tr mat-header-row
                                *matHeaderRowDef="['scopes', 'actions']">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: scopeTableColumns;">
                            </tr>
                        </mat-table>
                    </div>
                    <div class="cb-table flex-col flex"
                         style="overflow: visible">
                        <mat-table #nationalProductsTable
                                   [attr.data-name]="'nationalProductsTable'"
                                   [dataSource]="buildActivity.getNationalSchedule().productsSuppliedByLineItems">
                            <ng-container matColumnDef="products">
                                <th mat-header-cell
                                    *matHeaderCellDef>
                                    Products Supplied by Classic
                                </th>
                                <td mat-cell
                                    *matCellDef="let product; let i = index;">
                                    <div class="cb-schedule-textarea-container start-center flex-row flex">
                                        <b>
                                            {{product.sortOrder}}
                                        </b>
                                        <textarea [attr.data-line-item]="'products' + product.sortOrder"
                                                  cbTextAreaAutoUpdateHeight
                                                  class="{{'products' + product.sortOrder}}"
                                                  [maxlength]="250"
                                                  cbTrimValue
                                                  name="productNationalText-{{i}}"
                                                  rows="1"
                                                  [(ngModel)]="product.text"
                                                  mat-select-on-focus>
                                        </textarea>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell
                                    *matHeaderCellDef>
                                    <button mat-icon-button
                                            color="accent"
                                            (click)="addLineItem('productsSuppliedByLineItems', 'nationalProductsTable', 'regionalProductsTable')">
                                        <mat-icon>
                                            add
                                        </mat-icon>
                                    </button>
                                </th>
                                <td mat-cell
                                    *matCellDef="let product">
                                    <span class="counter mat-body">
                                        {{product?.text?.length}} / 250
                                    </span>
                                </td>
                            </ng-container>
                            <tr mat-header-row
                                *matHeaderRowDef="['products', 'actions']">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: productTableColumns;">
                            </tr>
                        </mat-table>
                    </div>
                </div>
                <mat-tab-group class="flex-col flex-50"
                               (selectedTabChange)="regionalTabChanged()">
                    <mat-tab [label]="region.name"
                             *ngFor="let region of regions">
                        <div class="start-center flex-row">
                            <cb-checkbox name="HideSsrLineQuantitiesRegion-{{region.id}}"
                                         label="Hide SSR Line Quantities"
                                         class="cb-margin-bottom-2x cb-margin-right-3x cb-margin-top-2x"
                                         [(ngModel)]="buildActivity.getActivityScheduleByRegion(region.id).shouldHideSsrLineQuantities">
                            </cb-checkbox>
                            <cb-checkbox name="HideSsrLineSubtotalsRegion-{{region.id}}"
                                         label="Hide SSR Line Subtotals"
                                         class="cb-margin-bottom-2x cb-margin-top-2x"
                                         [(ngModel)]="buildActivity.getActivityScheduleByRegion(region.id).shouldHideSsrLineSubtotals">
                            </cb-checkbox>
                        </div>
                        <div class="cb-table flex-col flex"
                             style="overflow: visible">
                            <mat-table #regionalSpecificationsTable
                                       [attr.data-name]="'regionalSpecificationsTable'"
                                       [dataSource]="buildActivity.getActivityScheduleByRegion(region.id).buildActivityScheduleSpecificationsLineItems">
                                <ng-container matColumnDef="specifications">
                                    <th mat-header-cell
                                        *matHeaderCellDef>
                                        Specifications
                                    </th>
                                    <td mat-cell
                                        *matCellDef="let spec; let i = index;">
                                        <div class="cb-schedule-textarea-container start-center flex-row flex">
                                            <b>
                                                {{spec.sortOrder}}
                                            </b>
                                            <textarea [attr.data-line-item]="'specifications' + spec.sortOrder"
                                                      cbTextAreaAutoUpdateHeight
                                                      class="{{'specifications' + spec.sortOrder}}"
                                                      [maxlength]="250"
                                                      name="specificationRegion-{{region.id}}Text-{{i}}"
                                                      rows="1"
                                                      cbTrimValue
                                                      type="text"
                                                      [(ngModel)]="spec['text']"
                                                      mat-select-on-focus>
                                            </textarea>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell
                                        *matHeaderCellDef>
                                        <button mat-icon-button
                                                color="accent"
                                                (click)="copyContentFromNational(region.id, 'buildActivityScheduleSpecificationsLineItems')">
                                            <mat-icon>
                                                content_copy
                                            </mat-icon>
                                        </button>
                                    </th>
                                    <td mat-cell
                                        *matCellDef="let spec">
                                        <span class="counter mat-body">
                                            {{spec?.text?.length}} / 250
                                        </span>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="['specifications', 'actions']">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: specTableColumns;">
                                </tr>
                            </mat-table>
                        </div>
                        <div class="cb-table flex-col flex"
                             style="overflow: visible">
                            <mat-table #regionalScopesTable
                                       [attr.data-name]="'regionalScopesTable'"
                                       [dataSource]="buildActivity.getActivityScheduleByRegion(region.id).scopeOfWorkLineItems">
                                <ng-container matColumnDef="scopes">
                                    <th mat-header-cell
                                        *matHeaderCellDef>
                                        Scope of Work
                                    </th>
                                    <td mat-cell
                                        *matCellDef="let scope; let i = index;">
                                        <div class="cb-schedule-textarea-container start-center flex-row flex">
                                            <b>
                                                {{scope.sortOrder}}
                                            </b>
                                            <textarea [attr.data-line-item]="'scopes' + scope.sortOrder"
                                                      cbTextAreaAutoUpdateHeight
                                                      class="{{'scopes' + scope.sortOrder}}"
                                                      [maxlength]="250"
                                                      cbTrimValue
                                                      name="scopeRegion-{{region.id}}Text-{{i}}"
                                                      rows="1"
                                                      [(ngModel)]="scope.text"
                                                      mat-select-on-focus>
                                            </textarea>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell
                                        *matHeaderCellDef>
                                        <button mat-icon-button
                                                color="accent"
                                                (click)="copyContentFromNational(region.id, 'scopeOfWorkLineItems')">
                                            <mat-icon>
                                                content_copy
                                            </mat-icon>
                                        </button>
                                    </th>
                                    <td mat-cell
                                        *matCellDef="let scope">
                                        <span class="counter mat-body">
                                            {{scope?.text?.length}} / 250
                                        </span>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="['scopes', 'actions']">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: scopeTableColumns;">
                                </tr>
                            </mat-table>
                        </div>
                        <div class="cb-table flex-col flex"
                             style="overflow: visible">
                            <mat-table #regionalProductsTable
                                       [attr.data-name]="'regionalProductsTable'"
                                       [dataSource]="buildActivity.getActivityScheduleByRegion(region.id).productsSuppliedByLineItems">
                                <ng-container matColumnDef="products">
                                    <th mat-header-cell
                                        *matHeaderCellDef>
                                        Products Supplied by Classic
                                    </th>
                                    <td mat-cell
                                        *matCellDef="let product; let i = index;">
                                        <div class="cb-schedule-textarea-container start-center flex-row flex">
                                            <b>
                                                {{product.sortOrder}}
                                            </b>
                                            <textarea [attr.data-line-item]="'products' + product.sortOrder"
                                                      cbTextAreaAutoUpdateHeight
                                                      class="{{'products' + product.sortOrder}}"
                                                      [maxlength]="250"
                                                      name="productRegion-{{region.id}}Text-{{i}}"
                                                      rows="1"
                                                      cbTrimValue
                                                      [(ngModel)]="product.text"
                                                      mat-select-on-focus>
                                            </textarea>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell
                                        *matHeaderCellDef>
                                        <button mat-icon-button
                                                color="accent"
                                                (click)="copyContentFromNational(region.id, 'productsSuppliedByLineItems')">
                                            <mat-icon>
                                                content_copy
                                            </mat-icon>
                                        </button>
                                    </th>
                                    <td mat-cell
                                        *matCellDef="let product">
                                        <span class="counter mat-body">
                                            {{product?.text?.length}} / 250
                                        </span>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="['products', 'actions']">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: productTableColumns;">
                                </tr>
                            </mat-table>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>
