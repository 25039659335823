<div class="cb-max-100 flex-col flex">
    <mat-card class="top-panel cb-margin-bottom end-center flex-row flex">
        <cb-button class="cb-margin-right"
                   (clicked)="goBackToList()"
                   color="white"
                   *ngIf="displayCompare$ | async">
            <mat-icon>arrow_back</mat-icon>
            &nbsp;Back to Takeoff
        </cb-button>
        <span class="flex"></span>
        <div class="end-center flex-row"
             *ngIf="comparison$ | async">
            <cb-button class="cb-margin-right"
                       [disabled]="true"
                       *ngIf="(displayCompare$ | async) && !isTakeOffBudgetsEnabled()">
                Refresh Rates from Catalogue
            </cb-button>
            <cb-button class="cb-margin-right"
                       *ngIf="(rateVariations$ | async)?.length > 0"
                       color="warn"
                       (clicked)="showRateVariationDialog('rateVariations')">
                Price
                variations found
                ({{((rateVariations$ | async) | cbCallback: quotedVariationFilter)?.length || 0}})
            </cb-button>
            <cb-button class="cb-margin-right"
                       *ngIf="!isTakeOffBudgetsEnabled()"
                       (clicked)="resolveAll()"
                       [disabled]="true">Resolve All</cb-button>
            <cb-button class="cb-margin-right"
                       [disabled]="(hasRateVariations$ | async) || (allVariations$ | async)?.length < 1"
                       (clicked)="save()">Save</cb-button>
            <cb-button (clicked)="abandon()">Abandon</cb-button>
        </div>
    </mat-card>
    <form class="flex flex-row"
          #form="ngForm">
        <div class="cb-margin"
             *ngIf="(allVariations$ | async) && (allVariations$ | async)?.length < 1">
            <mat-icon class="mat-accent material-icons">
                info
            </mat-icon>
            &nbsp;No changes were found
        </div>
        <cb-loading-spinner class="cb-margin"
                            *ngIf="!(comparison$ | async)">
        </cb-loading-spinner>
        <mat-card *ngIf="(allVariations$ | async)?.length > 0"
                  class="cb-margin-right cb-left-window flex">
            <mat-tab-group mat-stretch-tabs="false">
                <mat-tab [label]="stage.name + ' (' + itemCount(stage) + ')'"
                         *ngFor="let stage of (comparison$ | async)?.stages">
                    <div class="standardTable flex-fill">
                        <table>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Code</td>
                                    <td>Item</td>
                                    <td>Original Quantity</td>
                                    <td style="text-align: right">Quantity</td>
                                    <td style="text-align: center">UOM</td>
                                    <td style="text-align: right">Imp Rate</td>
                                    <td style="text-align: right">Cat Rate</td>
                                    <td>Supplier</td>
                                    <td *ngIf="!isTakeOffBudgetsEnabled()">Cost Type</td>
                                    <td *ngIf="isTakeOffBudgetsEnabled()"
                                        style="text-align: right">Orig Budget</td>
                                    <td *ngIf="isTakeOffBudgetsEnabled()"
                                        style="text-align: right">Imp Budget</td>
                                    <td style="text-align: center">Not in Spec</td>
                                    <td style="text-align: center">Not in Take Off</td>
                                </tr>
                            </thead>
                            <tbody *ngFor="let activity of stage.activities">
                                <tr class="take-off-activity">
                                    <td style="width: 30px;">
                                        <cb-info-popup *ngIf="activity.notes"
                                                       icon="insert_drive_file"
                                                       [tooltip]="activity.notes">
                                        </cb-info-popup>
                                    </td>
                                    <td>{{activity.code}}</td>
                                    <td>{{activity.name}}</td>
                                    <td colspan="10"></td>
                                    <td *ngIf="isTakeOffBudgetsEnabled()"></td>
                                </tr>
                                <tr *ngFor="let item of activity.items">
                                    <td class="actions">
                                        <cb-info-popup *ngIf="item.originalNotes"
                                                       icon="content_copy"
                                                       [tooltip]="item.originalNotes">
                                        </cb-info-popup>
                                        <cb-info-popup *ngIf="item.notes"
                                                       icon="insert_drive_file"
                                                       [tooltip]="item.notes">
                                        </cb-info-popup>
                                    </td>
                                    <td>{{item.quoteLineId == undefined ? item.code : ('Q' +
                                        item.quoteLineId)}}</td>
                                    <td>{{item.name}}</td>
                                    <td style="text-align: right"
                                        [ngClass]="{'table-warn': $any(item).$quantitywarning}">
                                        {{item.originalQuantity | cbDasher}}</td>
                                    <td style="text-align: right"
                                        [ngClass]="{'table-warn': $any(item).$quantitywarning}">
                                        {{item.quantity}}</td>
                                    <td style="text-align: center">{{item.uom}}</td>
                                    <td style="text-align: right"
                                        [ngClass]="{'table-warn': $any(item).$warning}">
                                        {{ item.takeOffRate | cbCurrency }}
                                    </td>
                                    <td style="text-align: right"
                                        [ngClass]="{'table-warn': $any(item).$warning}">
                                        {{ item.catalogueRate | cbCurrency}}
                                    </td>
                                    <td>{{item.supplier | cbDasher}}</td>
                                    <cb-td-enum *ngIf="!isTakeOffBudgetsEnabled()"
                                                [value]="item.costType"
                                                [enum]="COST_TYPE_ENUM">
                                    </cb-td-enum>
                                    <td *ngIf="isTakeOffBudgetsEnabled()"
                                        style="text-align: right"
                                        [ngClass]="{'table-warn': $any(item).$budgetWarning}">
                                        {{ item.hasBudget ? (item.originalBudget | cbCurrency) : '' }}
                                    </td>
                                    <td *ngIf="isTakeOffBudgetsEnabled()"
                                        style="text-align: right"
                                        [ngClass]="{'table-warn': $any(item).$budgetWarning}">
                                        {{ item.hasBudget ? (item.budget | cbCurrency) : '' }}
                                    </td>
                                    <td style="text-align: center">
                                        <mat-icon>{{item.isNotInSpec ? "check_box" :
                                            "check_box_outline_blank"}}</mat-icon>
                                    </td>
                                    <td style="text-align: center">
                                        <mat-icon>{{item.isNotInTakeOff ? "check_box" :
                                            "check_box_outline_blank"}}</mat-icon>
                                    </td>
                                </tr>
                                <tr class="empty-table-row">
                                    <td colspan="13"></td>
                                    <td *ngIf="isTakeOffBudgetsEnabled()"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
        <div class="flex"
             *ngIf="(allVariations$ | async)?.length < 1">
        </div>
        <mat-card class="flex-row flex"
                  id="alerts"
                  *ngIf="comparison$ | async">
            <div class="start-start flex-col flex">
                <div class="start-start flex-row"
                     style="margin-bottom: 10px">
                    <mat-icon style="margin-right: 8px">announcement</mat-icon>
                    <h1 class="mat-body-1">
                        <b>Alerts</b>
                    </h1>
                </div>
                <cb-alert-card-new type="error"
                                   *ngFor="let variationsWithoutCatalogueRate of (variationsWithoutCatalogueRates$ | async) | cbCallback: quotedVariationFilter"
                                   style="width: 100%; margin-bottom: 4px"
                                   message="Item
                                           {{variationsWithoutCatalogueRate.name}} has no
                                           Catalogue Rate">
                </cb-alert-card-new>
                <cb-alert-card-new type="error"
                                   *ngIf="(rateVariations$ | async)?.length > 1"
                                   message="There are {{(rateVariations$ |
                                            async)?.length}}
                                            Rate
                                            Variances
                                            that need resolving"
                                   style="width: 100%; margin-bottom: 4px">
                </cb-alert-card-new>
                <cb-alert-card-new type="error"
                                   message="There is {{(rateVariations$ |
                                async)?.length}}
                                Rate
                                Variance
                                that needs resolving"
                                   *ngIf="((rateVariations$ | async)?.length > 0) && (!isTakeOffBudgetsEnabled() || (hasRateVariations$ | async))"
                                   style="width: 100%; margin-bottom: 4px">
                </cb-alert-card-new>
                <ng-container *ngFor="let lotSpecItemNotInTakeoff of (comparison$ | async)?.lotSpecItemsNotInTakeoff">
                    <cb-alert-card-new type="warning"
                                       message="Lot Spec
                                Item {{lotSpecItemNotInTakeoff.productDisplay}} is not Included in the Takeoff Data"
                                       *ngIf="lotSpecItemNotInTakeoff.productId"
                                       style="width: 100%; margin-bottom: 4px">
                    </cb-alert-card-new>
                </ng-container>
                <span class="mat-body-1"
                      *ngIf="noAlerts$ | async">
                    <mat-icon class="mat-accent">info_circle</mat-icon>
                    &nbsp;There are no alerts to display
                </span>
            </div>
        </mat-card>
    </form>
</div>
