import { CbHandleInvalidImageModule } from '@app/shared/directives/handle-invalid-image/handle-invalid-image.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule as MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatTabsModule } from '@angular/material/tabs';
import { AddEditStandardPlanCostDialogComponent } from './add-edit-standard-plan-cost-dialog/add-edit-standard-plan-cost-dialog';
import { CbAmenitiesModule } from '@app/shared/components/amenities/amenities.module';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbLazyLoadImageModule } from '@app/shared/directives/lazy-load-img/lazy-load-img.module';
import { CbSortableHeaderCellModule } from '@app/shared/components/sortable-header-cell/sortable-header-cell.module';
import { CbStylesSettingModule } from '@app/views/settings/pages/standard-plans/styles/styles.module';
import { CommonModule } from '@angular/common';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StandardPlanCardComponent } from './standard-plan-card/standard-plan-card.component';
import { StandardPlanCostsComponent } from './standard-plan-costs/standard-plan-costs.component';
import { StandardPlanDetailsEditComponent } from './standard-plan-details-edit/standard-plan-details-edit.component';
import { StandardPlanDetailsViewComponent } from './standard-plan-details-view/standard-plan-details.view.component';
import { StandardPlanDialogComponent } from './standard-plan-dialog/standard-plan-dialog';
import { StandardPlanSearchComponent } from './standard-plan-search/standard-plan-search.component';
import { StandardPlanSearchFilterComponent } from './standard-plan-search-filter-dialog/standard-plan-search-filter.component';
import { StandardPlansComponent } from './standard-plans/standard-plans.component';
import { UploadImageDialogComponent } from './manage-image/upload-image-dialog/upload-image-dialog.component';
import { ViewImageComponent } from '@app/shared/components/image-handlers/view-image/view-image.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbDasherModule, CbInfoMessageModule, CbLoadingSpinnerModule } from 'cb-hub-lib';

@NgModule({
    declarations: [
        AddEditStandardPlanCostDialogComponent,
        StandardPlanCardComponent,
        StandardPlanCostsComponent,
        StandardPlanDetailsEditComponent,
        StandardPlanDetailsViewComponent,
        StandardPlanDialogComponent,
        StandardPlanSearchComponent,
        StandardPlanSearchFilterComponent,
        StandardPlansComponent,
        UploadImageDialogComponent,
        ViewImageComponent,
    ],
    imports: [
        CbAmenitiesModule,
        CbDialogModule,
        CbDasherModule,
        CbDialogModule,
        CbDisplayValueModule,
        CbFormsModule,
        CbInfoMessageModule,
        CbLazyLoadImageModule,
        CbLoadingSpinnerModule,
        CbSortableHeaderCellModule,
        CbStylesSettingModule,
        CommonModule,
        FormsModule,
        InfiniteScrollModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatInputModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        NgxSliderModule,
        ReactiveFormsModule,
        MatTooltipModule,
        CbCurrencyModule,
        CbHandleInvalidImageModule,
        RouterModule.forRoot([
            {
                component: StandardPlansComponent,
                // angular 7+ root routes must start with 'ng'
                path: 'standard-plans',
                data: {
                    breadcrumb: 'Standard Plans'
                }
            }
        ], {}),

    ],
    exports: [
        AddEditStandardPlanCostDialogComponent,
        StandardPlanCardComponent,
        StandardPlanDialogComponent,
        StandardPlanSearchComponent,
        StandardPlanSearchFilterComponent,
        UploadImageDialogComponent,
        ViewImageComponent,
        InfiniteScrollModule
    ]
})
export class StandardPlansModule { }
