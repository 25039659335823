import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbTableModule, CbSelectListModule, CbLoadingSpinnerModule } from 'cb-hub-lib';
import { LotSearchComponent } from './lot-search/lot-search.component';
import { LotSearchFilterComponent } from './lot-search-filter/lot-search-filter.component';
import { LotCardComponent } from './lot-card/lot-card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { LotSearchPopupFilterComponent } from './lot-search-popup-filter/lot-search-popup-filter.component';
import { FormsModule } from '@angular/forms';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbInfiniteScrollModule } from 'projects/cb-hub-lib/src/lib/directives/infinite-scroll/infinite-scroll.module';
import { CbInfoMessageModule } from 'projects/cb-hub-lib/src/lib/components/info-message/info-message.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';

const MAT_MODULES = [
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatMenuModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxSliderModule,
        CbFormsModule,
        CbSelectListModule,
        CbInfiniteScrollModule,
        CbInfoMessageModule,
        CbDisplayValueModule,
        CbWaitForModule,
        CbTableModule,
        CbLoadingSpinnerModule,
        ...MAT_MODULES
    ],
    exports: [
        LotSearchComponent
    ],
    declarations: [
        LotSearchComponent,
        LotSearchFilterComponent,
        LotSearchPopupFilterComponent,
        LotCardComponent
    ],
})
export class LotSearchModule { }
