<mat-card class="cb-margin flex-col flex">
    <div class="flex-row flex">
        <div *ngIf="mappedItem"
             class="cb-margin-right-2x flex-col flex-33">
            <div class="start-center flex-row">
                <mat-icon class="cb-margin-right"
                          [ngClass]="getHtmlClassFromStatus()">
                    label
                </mat-icon>
                <h3 class="mat-title product-status"
                    [ngClass]="getHtmlClassFromStatus()">
                    {{mappedItem.productType | cbEnumToLabel: PRODUCT_TYPE_ENUM}} {{getStatusText()}}
                </h3>
            </div>
            <cb-image-gallery [images]="mappedItem.imageUrls"></cb-image-gallery>
            <div class="flex-col"
                 *ngIf="mappedItem.status > 1">
                <div class="cb-margin-top start-center flex-row">
                    <mat-icon class="cb-warn cb-margin-right">warning</mat-icon>
                    <h3 class="mat-title cb-warn product-status">
                        {{mappedItem.status === OFFERING_STATUS_ENUM.TemporarilyUnavailable ? 'Unavailable' :
                        'Inactive'}}&nbsp;
                    </h3>
                    <span class="flex"></span>
                </div>
                <mat-divider style="margin: 8px 0 8px 0; position: static;width:auto"></mat-divider>
                <cb-display-value-new *ngIf="mappedItem.unavailableReason && mappedItem.status === OFFERING_STATUS_ENUM.PermanentlyUnavailable"
                                      label="Reason"
                                      value="{{OFFERING_STATUS_ENUM[mappedItem.status]}}">
                </cb-display-value-new>
                <cb-display-value-new *ngIf="mappedItem.status === OFFERING_STATUS_ENUM.TemporarilyUnavailable"
                                      label="Unavailable Period">
                    <cb-user-date-time-new [date]="mappedItem.unavailableStartDate">
                    </cb-user-date-time-new>
                    &nbsp;
                    to
                    <cb-user-date-time-new [date]="mappedItem.unavailableEndDate">
                    </cb-user-date-time-new>
                </cb-display-value-new>
                <cb-display-value-new *ngIf="mappedItem.status === OFFERING_STATUS_ENUM.PermanentlyUnavailable"
                                      label="Inactive Start Date">
                    <cb-user-date-time-new [date]="mappedItem.unavailableStartDate">
                    </cb-user-date-time-new>
                </cb-display-value-new>
                <cb-display-value-new *ngIf="mappedItem.replacementOffering"
                                      label="Replacement Product">
                    <span class="start-center flex-row">
                        {{mappedItem?.replacementOffering?.name}}
                        <mat-icon style="height: 2px; margin-top: -20px; cursor: pointer"
                                  (click)="viewProduct(mappedItem.replacementOfferingId)">pageview</mat-icon>
                    </span>
                </cb-display-value-new>
                <cb-text-area *ngIf="mappedItem.statusComment"
                              label="Comments"
                              name="comments"
                              [(ngModel)]="mappedItem.statusComment"
                              [readonly]="true">
                </cb-text-area>
            </div>
        </div>
        <div class="flex-col flex-66"
             *ngIf="mappedItem">
            <h3 class="mat-title">{{mappedItem.code}} {{mappedItem.name}} <cb-display-pill [displayPillText]="'Standard'" *ngIf="mappedItem.isStandardProduct"></cb-display-pill></h3>

            <cb-display-value-new *ngIf="compositeProduct"
                                  label="Composite Item"
                                  [value]="getCompositeItemLabel()"
                                  [showBtn]="true"
                                  (click)="viewCompositeProduct()">
            </cb-display-value-new>
            <cb-display-value-new label="UOM"
                                  [value]="UNIT_OF_MEASURE_ENUM[mappedItem.uom]">
            </cb-display-value-new>
            <cb-display-value-new label="Categories">
                <ul class="simple-breadcrumbs">
                    <li *ngFor="let cat of mappedItem.categoryPath">{{cat.label}}</li>
                </ul>
            </cb-display-value-new>
            <cb-display-value-new *ngIf="mappedItem.isLabour()"
                                  label="Trade Type"
                                  [value]="mappedItem.tradeType.label">
            </cb-display-value-new>
            <cb-display-value-new label="Notes"
                                  [value]="mappedItem.notes">
            </cb-display-value-new>
            <span class="cb-margin"></span>
            <cb-standard-table *ngIf="mappedItem.isCompositeItem"
                               [value]="mappedItem.componentItems"
                               [columns]="assignedItemsColumns"
                               label="Assigned Items"
                               valueLabel="Assigned Items"
                               class="cb-margin-bottom-2x">
            </cb-standard-table>
            <cb-standard-table *ngIf="mappedItem.isProduct() && !mappedItem.isCompositeItem"
                               [value]="mappedItem.restrictions"
                               [columns]="restrictionsColumns"
                               [footerColumns]="restrictionsFooterColumns"
                               label="Restrictions"
                               valueLabel="Restrictions"
                               class="cb-margin-bottom-2x">
            </cb-standard-table>
            <div *ngIf="mappedItem?.isBundle()"
                 class="cb-margin-bottom">
                <h3 class="mat-title cb-margin-bottom">Bundle Items</h3>
                <div class="standardTable">
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>Item Code</td>
                                <td>Item Name</td>
                                <td>Quantity</td>
                                <td [style.width.px]="60">Status</td>
                                <td *ngIf="mappedItem.bundleTemplateId != undefined">Required</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody *ngIf="mappedItem.bundleTemplateId == undefined">
                            <tr *ngFor="let product of mappedItem.products | cbSortByPipe: 'offering': 'asc'">
                                <td></td>
                                <td>{{product.offeringId}}</td>
                                <td>{{product.offering}}</td>
                                <td>{{product.quantity}}</td>
                                <td>
                                    <mat-icon>{{product.isChangeable ? 'lock_open' : 'lock_outline'}}</mat-icon>
                                </td>
                                <td class="actions">
                                    <button mat-icon-button
                                            (cbClick)="viewProduct(product.offeringId)">
                                        <mat-icon>pageview</mat-icon>
                                    </button>
                                    <button [disabled]="!product.isChangeable"
                                            mat-icon-button
                                            (cbClick)="editBundleProductWithoutTemplate(product)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-icon-button
                                            (cbClick)="removeBundleProduct(product)">
                                        <mat-icon>remove_circle</mat-icon>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="actions">
                                    <button style="float: right;"
                                            mat-icon-button
                                            (cbClick)="addProductToBundle()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <ng-container *ngIf="mappedItem.bundleTemplateId != undefined">
                            <ng-container *ngFor="let item of mappedItem.templateItems; let index = index; let last = last;">
                                <tbody *ngIf="mappedItem.bundleTemplateItemProduct(item.id)">
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="5"
                                            class="mat-body-2">{{item.name}}</td>
                                    </tr>
                                    <tr>
                                        <td class="actions">
                                            <mat-icon *ngIf="mappedItem.bundleTemplateItemProduct(item.id).hasImage">
                                                photo</mat-icon>
                                            <mat-icon *ngIf="mappedItem.bundleTemplateItemProduct(item.id).inSpec">
                                                style
                                            </mat-icon>
                                        </td>
                                        <td>{{mappedItem.bundleTemplateItemProduct(item.id).offeringId || '-'}}</td>
                                        <td>{{mappedItem.bundleTemplateItemProduct(item.id).offering || '-'}} </td>
                                        <td>{{mappedItem.bundleTemplateItemProduct(item.id).quantity || '-'}} </td>
                                        <td class="actions">
                                            <mat-icon>{{item.isLocked ? 'lock' : 'lock_open'}}</mat-icon>
                                        </td>
                                        <td class="actions">
                                            <mat-icon *ngIf="item.isRequired">
                                                check_box
                                            </mat-icon>
                                            <mat-icon *ngIf="!item.isRequired">
                                                check_box_outline_blank
                                            </mat-icon>
                                        </td>
                                        <td class="actions">
                                            <button *ngIf="mappedItem.bundleTemplateItemProduct(item.id).offeringId != undefined"
                                                    mat-icon-button
                                                    (cbClick)="viewBundleProduct(mappedItem.bundleTemplateItemProduct(item.id))">
                                                <mat-icon>pageview</mat-icon>
                                            </button>
                                            <button [disabled]="getBundleTemplateItemDisabled(mappedItem, item)"
                                                    mat-icon-button
                                                    (cbClick)="editBundleProductWithTemplate(mappedItem.bundleTemplateItemProduct(item.id), item)">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="mappedItem.templateItems.length < 1">
                                        <td colspan="7">
                                            <div class="start-center flex-row">
                                                <mat-icon class="mat-accent">info</mat-icon>
                                                <span>&nbsp;There are no Bundle Items to display</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!mappedItem.bundleTemplateItemProduct(item.id)">
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="5"
                                            class="mat-body-2">{{item.name}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6"></td>
                                        <td class="actions">
                                            <button mat-icon-button
                                                    (cbClick)="addProductToTemplatedBundle(mappedItem.bundleTemplateItemProduct(item.id), item)">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </ng-container>
                        </ng-container>
                    </table>
                </div>
            </div>
            <ng-container *ngIf="!mappedItem.isCompositeItem">
                <cb-checkbox label="Include in Flyer / Create Schedule"
                             [readonly]="true"
                             [(ngModel)]="mappedItem.showInClientSpecs"
                             [slim]="true">
                </cb-checkbox>
                <cb-checkbox label="Included in Colour Schedule"
                             [readonly]="true"
                             [(ngModel)]="mappedItem.showInColourYourDreams"
                             [slim]="true">
                </cb-checkbox>
                <cb-checkbox label="Manual Colour Entry Required"
                             [readonly]="true"
                             [(ngModel)]="mappedItem.manualColourEntryRequired"
                             [slim]="true">
                </cb-checkbox>
                <cb-display-value-new *ngIf="mappedItem.showInClientSpecs"
                                      label="Spec Details"
                                      [value]="mappedItem.clientSpecDescription">
                </cb-display-value-new>
            </ng-container>
        </div>
    </div>
    <div class="flex"
         *ngIf="mappedItem && !mappedItem?.isBundle() && canViewRates()">
        <form class="flex"
              #ratesSearchForm="ngForm">
            <div class="flex-col">
                <mat-divider style="margin: 8px 0 20px 0; position: static;width:auto"></mat-divider>
                <div class="start-center flex-row flex">
                    <cb-select label="Supplier"
                               name="supplier"
                               [(ngModel)]="userCacheService.productSearch.data.selectedSupplier"
                               [options]="suppliers"
                               class="cb-margin-right-3x">
                    </cb-select>
                    <cb-checkbox label="Show Future Rates"
                                 name="showFutureRates"
                                 class="cb-margin-right-3x"
                                 [slim]="true"
                                 [(ngModel)]="userCacheService.productSearch.data.showFutureRates">
                    </cb-checkbox>
                    <cb-checkbox label="Show Inactive Rates"
                                 name="showInactiveRates"
                                 class="cb-margin-right-3x"
                                 [slim]="true"
                                 [(ngModel)]="userCacheService.productSearch.data.showInactiveRates">
                    </cb-checkbox>
                    <span class="flex"></span>
                    <cb-checkbox label="Rates are Maintained for this Product"
                                 [readonly]="true"
                                 [slim]="true"
                                 name="shouldMaintainRatesForThisProduct"
                                 [(ngModel)]="mappedItem.shouldMaintainRatesForThisProduct">
                    </cb-checkbox>
                </div>
                <div class="flex-col flex">
                    <mat-tab-group class="flex"
                                   mat-stretch-tabs="false"
                                   (selectedTabChange)="scrollDown()">
                        <mat-tab label="{{ratesForRegion.region.label}}"
                                 *ngFor="let ratesForRegion of rateRegions">
                            <div class="cb-margin-top flex-row">
                                <div class="flex-col flex">
                                    <div class="standardTable"
                                         cb-double-horiz-scroll="1000"
                                         *ngIf="!mappedItem.isCompositeItem"
                                         id="scrollId">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td *ngIf="mappedItem.isProduct()">
                                                        Supplier SKU</td>
                                                    <td>Supplier</td>
                                                    <td>Location</td>
                                                    <td>Project</td>
                                                    <td>Rate</td>
                                                    <td *ngIf="!mappedItem.isCompositeItem">Date Rate Applies</td>
                                                    <td *ngIf="!mappedItem.isCompositeItem">Updated</td>
                                                    <td *ngIf="!mappedItem.isCompositeItem">Updated By</td>
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let rate of ratesForRegion.rates | cbCallback: rateFilter"
                                                    [ngClass]="{'table-warn': !rate.isActive, 'table-accent': isFutureRate(rate)}">
                                                    <td *ngIf="mappedItem.isProduct()">
                                                        {{rate.sku | cbDasher}}
                                                    </td>
                                                    <td>{{rate.vendor | cbDasher}}</td>
                                                    <td>
                                                        <ul class="simple-breadcrumbs">
                                                            <li *ngFor="let area of rate.areaPath">
                                                                {{area.label | cbDasher}}</li>
                                                        </ul>
                                                    </td>
                                                    <td>{{rate.projectTitle | cbDasher}}</td>
                                                    <td>{{rate.value | currency}}</td>
                                                    <td>
                                                        <cb-user-date-time-new [date]="rate.appliedRatesFrom"
                                                                               [displayTime]="false">
                                                        </cb-user-date-time-new>
                                                    </td>
                                                    <td>
                                                        <cb-user-date-time-new [date]="rate.updatedDate"
                                                                               [displayTime]="false">
                                                        </cb-user-date-time-new>
                                                    </td>
                                                    <td>{{rate?.updatedBy?.firstName}}
                                                        {{rate?.updatedBy?.lastName}}
                                                    </td>
                                                    <td class="actions">
                                                        <button mat-icon-button
                                                                title="Edit Rate"
                                                                (cbClick)="manageRate(rate)"
                                                                class="cb-margin-right">
                                                            <mat-icon>edit</mat-icon>
                                                        </button>
                                                        <button mat-icon-button
                                                                title="View Supplier"
                                                                *ngIf="rate.businessAccountId"
                                                                (cbClick)="viewBusinessAccount(rate.businessAccountId)">
                                                            <mat-icon>pageview</mat-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="!(ratesForRegion.rates | cbCallback: rateFilter).length">
                                                    <td colspan="9">There are no rates for this Product with the
                                                        selected filters</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="standardTable"
                                         cb-double-horiz-scroll="1000"
                                         *ngIf="mappedItem.isCompositeItem"
                                         id="scrollId">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>Supplier</td>
                                                    <td>Location</td>
                                                    <td>Project</td>
                                                    <td>Rate</td>
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let rate of ratesForRegion.rates | cbCallback: compositeRateFilter"
                                                    [ngClass]="{'table-warn': !rate.locations[0].isActive, 'table-accent': isFutureRate(rate.locations[0])}">
                                                    <td>{{rate.supplier | cbDasher}}</td>
                                                    <td>
                                                        <ul class="simple-breadcrumbs">
                                                            <li *ngFor="let area of rate.locations">
                                                                {{area ? area.locationLabel : ''}}</li>
                                                        </ul>
                                                    </td>
                                                    <td>{{rate.locations[0].projectTitle | cbDasher}}</td>
                                                    <td>{{rate.locations[0].aggregateValue | currency | cbDasher}}</td>
                                                    <td class="actions">
                                                        <button mat-icon-button
                                                                title="View Rate Composition"
                                                                (cbClick)="openRateCompositionDialog(rate)">
                                                            <mat-icon>forward</mat-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="!(ratesForRegion.rates | cbCallback: compositeRateFilter).length">
                                                    <td colspan="5">There are no rates for this Product with the
                                                        selected filters</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </form>
    </div>
</mat-card>
